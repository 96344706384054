import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import TextInput from "../../../Components/Dinput";
import { toast } from "react-toastify";
import Button from "../../../Components/Dbutton";
import { useGetFriendsMutation } from "../../../services/friends";
import { useSelector } from "react-redux";
import { formatLargeNumber } from "../../../utils/common";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/logoIconNew.svg`;
const LogoIconforReward = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const RightArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/right-arrow.svg`;
const ReloadIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/reload-icon.svg`;
const CopyIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/copy-icon.svg`;

const DfriendList = () => {
  const [referralLink, setReferralLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const isLoadingRef = useRef(isLoading);
  const [listItems, setListItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const { user } = useSelector((state) => state.auth);
  const { friendsCount } = useSelector((state) => state.friends);

  const [getReferralFriends, { isLoading: isFriendsLoading }] =
    useGetFriendsMutation();
  const containerRef = useRef(null);
  const fetchFriendsList = useCallback(async () => {
    const params = {
      page: pagination.page,
      limit: pagination.pageSize,
    };
    getReferralFriends({ id: user?._id, params })
      .unwrap()
      .then((res) => {
        const data = res.data.data;
        setListItems((prevItems) => [...prevItems, ...data]);
        if (data < pagination.pageSize) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  }, [getReferralFriends, pagination.page, pagination.pageSize, user?._id]);

  useEffect(() => {
    if (user) {
      const baseUrl = process.env.REACT_APP_SIGNUP_URL;
      const encodedUsername = encodeURIComponent(user.userName);
      const generatedReferralLink = `${baseUrl}?ref=${user?.playFabId}&username=${encodedUsername}`;
      setReferralLink(generatedReferralLink);
      fetchFriendsList();
    }
  }, [fetchFriendsList, user, pagination.page]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          toast.success("Link copied to clipboard!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to copy text: ");
        });
    } else {
      toast.error("No referral link to copy!");
    }
  };

  // Handle the scroll event
  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >= container.scrollHeight &&
      hasMore &&
      !isFriendsLoading
    ) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 })); // Load next page
    }
  };

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>invite friends</h2>
          </div>
        </div>
        <div className="airdrop-banner">
          <div>
            <h2>How it works?</h2>
            <p>
              <b className="white"> Bonus 1:</b> Earn 10% for your direct
              friends referrals and 8% of their referrals
              <br />
              <b className="white"> Bonus 2:</b> You and your friend earn 10K
              extra coin
            </p>
          </div>
          <div>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
        </div>
        {listItems.length > 0 && <>
          <div className="frined-reward-bar">
            <h4 className="fouteen-size">your referral reward</h4>{" "}
            <div className="icon-point">
              <img
                src={LogoIconforReward}
                alt="Logo-icon"
                className="tool-icon"
              />
              <span>
                {friendsCount > 0 ? formatLargeNumber(friendsCount * 1100) : 0}
              </span>
              <img
                src={RightArrow}
                alt="right-Arrow-icon"
                className="tool-icon"
              />
            </div>
          </div>
          <div className="frined-count-bar">
            <h4 className="fouteen-size">
              list of your friends{" "}
              <div className="d-tool-tip-counter">
                {friendsCount > 0 ? friendsCount : 0}
              </div>
            </h4>
            <img
              src={ReloadIcon}
              alt="reload-icon"
              onClick={() => {
                fetchFriendsList();
                setIsLoading(true);
              }}
              className={isLoading ? "image-loading" : ""}
            />
          </div>
          <div className="friends-list-wrapper">
            <div
              className={`friend-list ${isLoading ? "loader-center" : ""}`}
              ref={containerRef}
              onScroll={handleScroll}
            >
              {isLoading ? (
                <div className="loader-center">
                  <div className="loader"></div>
                </div>
              ) : (
                <>
                  {!isFriendsLoading && friendsCount === 0 ? (
                    <div className="no-data">You haven’t invited anyone yet</div>
                  ) : (
                    <>
                      {listItems?.map((item, index) => {
                        return (
                          <div className="friend-list-item" key={index}>
                            <div className="user-item">
                              <img
                                src={
                                  item?.referDetails?.avatarUrl ||
                                  "https://via.placeholder.com/40"
                                }
                                alt={item.title}
                                className="user-img"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "https://via.placeholder.com/40";
                                }}
                              />
                              {item?.referDetails?.displayName}
                            </div>
                            <div className="icon-point">
                              <img
                                src={LogoIconforReward}
                                alt="Logo-icon"
                                className="tool-icon"
                              />
                              <span>
                                +{formatLargeNumber(item?.referredCoins)}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </div></>}
        <div className="freind-invite-bar">
          <div className="freind-invite-bar">
            <TextInput
              type="text"
              value={referralLink}
              extraClasses="wallet-input"
              rightNode={
                <img
                  src={CopyIcon}
                  alt="copy-icon"
                  onClick={() => {
                    copyToClipboard();
                  }}
                  className="input-node-icon"
                />
              }
            />
            <Button
              variant="primary-contained"
              text="invite a friend"
              onClick={() => {
                // copyToClipboard();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DfriendList;
