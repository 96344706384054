const baseUrl = `${process.env.REACT_APP_S3_BUCKET}/Images`;

const FelsightBg = `${baseUrl}/Felsight-bg.png`;
const FelsightCharactor = `${baseUrl}/Felsight-charactor.png`;
const FelsightIcon1 = `${baseUrl}/Felsight-Icon-1.png`;
const FelsightIcon2 = `${baseUrl}/Felsight-Icon-2.png`;
const FelsightIcon3 = `${baseUrl}/Felsight-Icon-3.png`;
const FelsightIcon4 = `${baseUrl}/Felsight-Icon-4.png`;

const FernixBg = `${baseUrl}/Fernix-bg.png`;
const FernixCharactor = `${baseUrl}/Fernix-charactor.png`;
const FernixIcon1 = `${baseUrl}/Fernix-Icon-1.png`;
const FernixIcon2 = `${baseUrl}/Fernix-Icon-2.png`;
const FernixIcon3 = `${baseUrl}/Fernix-Icon-3.png`;
const FernixIcon4 = `${baseUrl}/Fernix-Icon-4.png`;

const Drysiamain = `${baseUrl}/Drysiamain-min.png`;
const DrysiaBg = `${baseUrl}/Drysia.png`;
const DrysiaCharactor = `${baseUrl}/Drysia-chractor.png`;
const DrysiaIcon1 = `${baseUrl}/Drysia-Icon-1.png`;
const DrysiaIcon2 = `${baseUrl}/Drysia-Icon-2.png`;
const DrysiaIcon3 = `${baseUrl}/Drysia-Icon-3.png`;
const DrysiaIcon4 = `${baseUrl}/Drysia-Icon-4.png`;

const FernoBg = `${baseUrl}/Ferno-bg.png`;
const FernoCharactor = `${baseUrl}/Ferno-charactor.png`;
const FernoIcon1 = `${baseUrl}/Ferno-Icon-1.png`;
const FernoIcon2 = `${baseUrl}/Ferno-Icon-2.png`;
const FernoIcon3 = `${baseUrl}/Ferno-Icon-3.png`;
const FernoIcon4 = `${baseUrl}/Ferno-Icon-4.png`;

const FloraBg = `${baseUrl}/Flora-bg.png`;
const FloraCharactor = `${baseUrl}/Flora-charactor.png`;
const FloraIcon1 = `${baseUrl}/Flora-Icon-1.png`;
const FloraIcon2 = `${baseUrl}/Flora-Icon-2.png`;
const FloraIcon3 = `${baseUrl}/Flora-Icon-3.png`;
const FloraIcon4 = `${baseUrl}/Flora-Icon-4.png`;

const KaliBg = `${baseUrl}/Kali-bg.png`;
const KaliCharactor = `${baseUrl}/Kali-charactor.png`;
const KaliIcon1 = `${baseUrl}/Kali-Icon-1.png`;
const KaliIcon2 = `${baseUrl}/Kali-Icon-2.png`;
const KaliIcon3 = `${baseUrl}/Kali-Icon-3.png`;
const KaliIcon4 = `${baseUrl}/Kali-Icon-4.png`;

const LuminaBg = `${baseUrl}/Lumina-bg.png`;
const LuminaCharactor = `${baseUrl}/Lumina-charactor.png`;
const LuminaIcon1 = `${baseUrl}/Lumina-Icon-1.png`;
const LuminaIcon2 = `${baseUrl}/Lumina-Icon-2.png`;
const LuminaIcon3 = `${baseUrl}/Lumina-Icon-3.png`;
const LuminaIcon4 = `${baseUrl}/Lumina-Icon-4.png`;

const NobleBg = `${baseUrl}/Noble-bg.png`;
const NobleCharactor = `${baseUrl}/Noble-charactor.png`;
const NobleIcon1 = `${baseUrl}/Noble-Icon-1.png`;
const NobleIcon2 = `${baseUrl}/Noble-Icon-2.png`;
const NobleIcon3 = `${baseUrl}/Noble-Icon-3.png`;
const NobleIcon4 = `${baseUrl}/Noble-Icon-4.png`;

const RavosBg = `${baseUrl}/Ravos-bg.png`;
const RavosCharactor = `${baseUrl}/Ravos-charactor.png`;
const RavosIcon1 = `${baseUrl}/Ravos-Icon-1.png`;
const RavosIcon2 = `${baseUrl}/Ravos-Icon-2.png`;
const RavosIcon3 = `${baseUrl}/Ravos-Icon-3.png`;
const RavosIcon4 = `${baseUrl}/Ravos-Icon-4.png`;

const SeraphinaBg = `${baseUrl}/Seraphina-bg.png`;
const SeraphinaCharactor = `${baseUrl}/Seraphina-charactor.png`;
const SeraphinaIcon1 = `${baseUrl}/Seraphina-Icon-1.png`;
const SeraphinaIcon2 = `${baseUrl}/Seraphina-Icon-2.png`;
const SeraphinaIcon3 = `${baseUrl}/Seraphina-Icon-3.png`;
const SeraphinaIcon4 = `${baseUrl}/Seraphina-Icon-4.png`;

const SilverfangBg = `${baseUrl}/Silverfang-bg.png`;
const SilverfangCharactor = `${baseUrl}/Silverfang-charactor.png`;
const SilverfangIcon1 = `${baseUrl}/Silverfang-Icon-1.png`;
const SilverfangIcon2 = `${baseUrl}/Silverfang-Icon-2.png`;
const SilverfangIcon3 = `${baseUrl}/Silverfang-Icon-3.png`;
const SilverfangIcon4 = `${baseUrl}/Silverfang-Icon-4.png`;

const TauroniusBg = `${baseUrl}/Tauronius-bg.png`;
const TauroniusCharactor = `${baseUrl}/Tauronius-charactor.png`;
const TauroniusIcon1 = `${baseUrl}/Tauronius-Icon-1.png`;
const TauroniusIcon2 = `${baseUrl}/Tauronius-Icon-2.png`;
const TauroniusIcon3 = `${baseUrl}/Tauronius-Icon-3.png`;
const TauroniusIcon4 = `${baseUrl}/Tauronius-Icon-4.png`;

const MycoBg = `${baseUrl}/Mycobg.png`;
const MycoCharactor = `${baseUrl}/MycoCharactor.png`;
const MycoIcon1 = `${baseUrl}/MycoIcon1.png`;
const MycoIcon2 = `${baseUrl}/MycoIcon2.png`;
const MycoIcon3 = `${baseUrl}/MycoIcon3.png`;
const MycoIcon4 = `${baseUrl}/MycoIcon4.png`;

const GortharBg = `${baseUrl}/Gortharbg.png`;
const GortharCharactor = `${baseUrl}/GortharCharactor.png`;
const GortharIcon1 = `${baseUrl}/GortharIcon1.png`;
const GortharIcon2 = `${baseUrl}/GortharIcon2.png`;
const GortharIcon3 = `${baseUrl}/GortharIcon3.png`;
const GortharIcon4 = `${baseUrl}/GortharIcon4.png`;

// const GorlockBg = `${baseUrl}/Gorlockbg.png`;
// const GorlockCharactor = `${baseUrl}/GorlockCharactor.png`;
// const GorlockIcon1 = `${baseUrl}/GorlockIcon1.png`;
// const GorlockIcon2 = `${baseUrl}/GorlockIcon2.png`;
// const GorlockIcon3 = `${baseUrl}/GorlockIcon3.png`;
// const GorlockIcon4 = `${baseUrl}/GorlockIcon4.png`;

const ZephyrBg = `${baseUrl}/Zephyrbg.png`;
const ZephyrCharactor = `${baseUrl}/ZephyrCharactor.png`;
const ZephyrIcon1 = `${baseUrl}/Zephyr1.png`;
const ZephyrIcon2 = `${baseUrl}/Zephyr2.png`;
const ZephyrIcon3 = `${baseUrl}/Zephyr3.png`;
const ZephyrIcon4 = `${baseUrl}/Zephyr4.png`;

const DrakonBg = `${baseUrl}/DrakonBg.png`;
const DrakonCharactor = `${baseUrl}/DrakonCharactor.png`;
const DrakonIcon1 = `${baseUrl}/DrakonIcon1.png`;
const DrakonIcon2 = `${baseUrl}/DrakonIcon2.png`;
const DrakonIcon3 = `${baseUrl}/DrakonIcon3.png`;
const DrakonIcon4 = `${baseUrl}/DrakonIcon4.png`;

const ZephyrionBg = `${baseUrl}/Zephyrion-bg.png`;
const ZephyrionCharactor = `${baseUrl}/Zephyrion-charactor.png`;
const ZephyrionIcon1 = `${baseUrl}/Zephyrion-Icon-1.png`;
const ZephyrionIcon2 = `${baseUrl}/Zephyrion-Icon-2.png`;
const ZephyrionIcon3 = `${baseUrl}/Zephyrion-Icon-3.png`;
const ZephyrionIcon4 = `${baseUrl}/Zephyrion-Icon-4.png`;

const VENOMBg = `${baseUrl}/VENOMbg.png`;
const VENOMCharactor = `${baseUrl}/VENOMCharactor.png`;
const VENOMIcon1 = `${baseUrl}/VENOMIcon1.png`;
const VENOMIcon2 = `${baseUrl}/VENOMIcon2.png`;
const VENOMIcon3 = `${baseUrl}/VENOMIcon3.png`;
const VENOMIcon4 = `${baseUrl}/VENOMIcon4.png`;

const ObsidianBg = `${baseUrl}/Obsidianbg.png`;
const ObsidianCharactor = `${baseUrl}/ObsidianCharactor.png`;
const ObsidianIcon11 = `${baseUrl}/ObsidianIcon11.png`;
const ObsidianIcon2 = `${baseUrl}/ObsidianIcon2.png`;
const ObsidianIcon3 = `${baseUrl}/ObsidianIcon3.png`;
const ObsidianIcon4 = `${baseUrl}/ObsidianIcon4.png`;

const AquariusBg = `${baseUrl}/Aquariusbg.png`;
const AquariusCharactor = `${baseUrl}/AquariusCharactor.png`;
const AquariusIcon1 = `${baseUrl}/AquariusIcon1.png`;
const AquariusIcon2 = `${baseUrl}/AquariusIcon2.png`;
const AquariusIcon3 = `${baseUrl}/AquariusIcon3.png`;
const AquariusIcon4 = `${baseUrl}/AquariusIcon4.png`;

const MortalexnBg = `${baseUrl}/Mortalexnbg.png`;
const MortalexCharactor = `${baseUrl}/MortalexCharactor.png`;
const MortalexnIcon1 = `${baseUrl}/MortalexnIcon1.png`;
const MortalexnIcon2 = `${baseUrl}/MortalexnIcon2.png`;
const MortalexnIcon3 = `${baseUrl}/MortalexnIcon3.png`;
const MortalexnIcon4 = `${baseUrl}/MortalexnIcon4.png`;

const ThornbarkBg = `${baseUrl}/Thornbarkbg.png`;
const ThornbarkCharactor = `${baseUrl}/ThornbarkCharactor.png`;
const ThornbarkIcon1 = `${baseUrl}/ThornbarkIcon1.png`;
const ThornbarkIcon2 = `${baseUrl}/ThornbarkIcon2.png`;
const ThornbarkIcon3 = `${baseUrl}/ThornbarkIcon3.png`;
const ThornbarkIcon4 = `${baseUrl}/ThornbarkIcon4.png`;

const KorgathBg = `${baseUrl}/Korgathbg.png`;
const KorgathCharactor = `${baseUrl}/KorgathCharactor.png`;
const KorgathIcon1 = `${baseUrl}/KorgathIcon1.png`;
const KorgathIcon2 = `${baseUrl}/KorgathIcon2.png`;
const KorgathIcon3 = `${baseUrl}/KorgathIcon3.png`;
const KorgathIcon4 = `${baseUrl}/KorgathIcon4.png`;

const UrsokBg = `${baseUrl}/Ursokbg.png`;
const UrsokCharactor = `${baseUrl}/UrsokCharactor.png`;
const UrsokIcon1 = `${baseUrl}/UrsokIcon1.png`;
const UrsokIcon2 = `${baseUrl}/UrsokIcon2.png`;
const UrsokIcon3 = `${baseUrl}/UrsokIcon3.png`;
const UrsokIcon4 = `${baseUrl}/UrsokIcon4.png`;

const TwinkleBg = `${baseUrl}/Twinklebg.png`;
const TwinkleCharactor = `${baseUrl}/TwinkleCharactor.png`;
const TwinkleIcon1 = `${baseUrl}/TwinkleIcon1.png`;
const TwinkleIcon2 = `${baseUrl}/TwinkleIcon2.png`;
const TwinkleIcon3 = `${baseUrl}/TwinkleIcon3.png`;
const TwinkleIcon4 = `${baseUrl}/TwinkleIcon4.png`;

const GrimvaldBg = `${baseUrl}/Grimvaldbg.png`;
const GrimvaldCharactor = `${baseUrl}/GrimvaldCharactor.png`;
const GrimvaldIcon1 = `${baseUrl}/GrimvaldIcon1.png`;
const GrimvaldIcon2 = `${baseUrl}/GrimvaldIcon2.png`;
const GrimvaldIcon3 = `${baseUrl}/GrimvaldIcon3.png`;
const GrimvaldIcon4 = `${baseUrl}/GrimvaldIcon4.png`;

const AmeliaBg = `${baseUrl}/Ameliabg.png`;
const AmeliaCharactor = `${baseUrl}/AmeliaCharactor.png`;
const AmeliaIcon1 = `${baseUrl}/AmeliaIcon1.png`;
const AmeliaIcon2 = `${baseUrl}/AmeliaIcon2.png`;
const AmeliaIcon3 = `${baseUrl}/AmeliaIcon3.png`;
const AmeliaIcon4 = `${baseUrl}/AmeliaIcon4.png`;

const PyrovineBg = `${baseUrl}/Pyrovinebg.png`;
const PyrovineCharactor = `${baseUrl}/PyrovineCharactor.png`;
const PyrovineIcon1 = `${baseUrl}/PyrovineIcon1.png`;
const PyrovineIcon2 = `${baseUrl}/PyrovineIcon2.png`;
const PyrovineIcon3 = `${baseUrl}/PyrovineIcon3.png`;
const PyrovineIcon4 = `${baseUrl}/PyrovineIcon4.png`;

const SylvanBg = `${baseUrl}/Sylvanbg.png`;
const SylvanCharactor = `${baseUrl}/SylvanCharactor.png`;
const SylvanIcon1 = `${baseUrl}/SylvanIcon1.png`;
const SylvanIcon2 = `${baseUrl}/SylvanIcon2.png`;
const SylvanIcon3 = `${baseUrl}/SylvanIcon3.png`;
const SylvanIcon4 = `${baseUrl}/SylvanIcon4.png`;


const typeIcon = `${baseUrl}/fightingIcon.svg`;
const SingleHerosImg = `${baseUrl}/videoposter.png`;
const FelsightMain = `${baseUrl}/felsight-main.png`;
const fernixmain = `${baseUrl}/fernixmain-min.png`;
const FlamelordHero = `${baseUrl}/hero-four-main-min.png`;
const Paladis = `${baseUrl}/Paladis-min.png`;
const RavenCrow = `${baseUrl}/RavenCrow-min.png`;
const VoltClaw = `${baseUrl}/aatrox-min.png`;
const FloraHero = `${baseUrl}/flora-main.png`;
const KalistaHero = `${baseUrl}/kali-main.png`;
const LuminaHero = `${baseUrl}/lumina-main.png`;
const SeraphinaHero = `${baseUrl}/seraphina-hero-min.jpg`;
const HeroTwo = `${baseUrl}/ahri-min.jpg`;
const Tauronis = `${baseUrl}/Tauronius-min.png`;
const Mycoo = `${baseUrl}/myco-main.png`;
const Gorthar = `${baseUrl}/gorthar-main.png`;
// const Gorlock = `${baseUrl}/gorlock-main.png`;
const Zephyr = `${baseUrl}/zephyr-main.png`;
const Drakon = `${baseUrl}/drakon-main.png`;
const VENOM = `${baseUrl}/venom-main.png`;
const Obsidian = `${baseUrl}/obsidian-main.png`;
const Aquarius = `${baseUrl}/aquarius-main.png`;
const Mortalex = `${baseUrl}/mortalex-main.png`;
const Thornbark= `${baseUrl}/thornbark-main.png`;
const Korgath= `${baseUrl}/korgath-main.png`;
const Ursok= `${baseUrl}/ursok-main.png`;
const Twinkle= `${baseUrl}/twinkle-main.png`;
const Grimvald= `${baseUrl}/grimvald-main.png`;
const Amelia= `${baseUrl}/amelia-main.png`;
const Pyrovine= `${baseUrl}/pyrovine-main.png`;
const Sylvan= `${baseUrl}/sylvan-main.png`;

export const HeroesData = [
  {
    image: Drysiamain,
    title: "Drysia",
    subtitle: "The Eclipse Warlord",
    description:
      "In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: DrysiaCharactor,
    bgImage: DrysiaBg,
    SingleHeroIcons: [
      {
        image: DrysiaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/Q.mp4",
          title: "Spiked Barrage",
          description:
            "Unleashes a flurry of sharp spikes that pierce enemies, causing damage over time.",
        },
      },
      {
        image: DrysiaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/W.mp4",
          title: "Celestial Drop",
          description:
            "Strikes from above weith a powerful impact, dealing with a powerful impact, dealing heavy damage and creating a shockwave that disrupts enemies.",
        },
      },
      {
        image: DrysiaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/E.mp4",
          title: "Dark Bolt",
          description:
            "Fires a bolt of dark energy that drains life from enemies, weakening them.",
        },
      },
      {
        image: DrysiaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/R.mp4",
          title: "Shadow Mend",
          description:
            "uses dark magic to heal herself over time, restoring her strength.",
        },
      },
    ],
  },
  {
    image: FelsightMain,
    title: "Felsight",
    subtitle: "The Mountain's Gaze",
    description:
      "From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in 'Guild of Heroes.' This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FelsightCharactor,
    bgImage: FelsightBg,
    SingleHeroIcons: [
      {
        image: FelsightIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/Q.mp4",
          title: "Cyclops Beam",
          description:
            "Fires a concentrated energy beam from his eye, piercing through multiple enemies.",
        },
      },
      {
        image: FelsightIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/W.mp4",
          title: "Ground Smash",
          description:
            "Strikes the ground to create a shockwave that knocks back and stuns enemies in the area.",
        },
      },
      {
        image: FelsightIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/E.mp4",
          title: "Rock Toss",
          description:
            "Hurls a large rock infused with magical energy, crushing foes upon impact.",
        },
      },
      {
        image: FelsightIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/R.mp4",
          title: "Stone Skin",
          description:
            "Temporarily hardens his skin, reducing damage taken and gradually healing wounds.",
        },
      },
    ],
  },
  {
    image: fernixmain,
    title: "Fernix",
    subtitle: "The Arcane Protector",
    description:
      "From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FernixCharactor,
    bgImage: FernixBg,
    SingleHeroIcons: [
      {
        image: FernixIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/Q.mp4",
          title: "Inferno Burst",
          description:
            "Draconis releases a blazing explosion from above, dealing heavy area damage and igniting enemies for additional burning damage.",
        },
      },
      {
        image: FernixIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/W.mp4",
          title: "Flame Wave",
          description:
            "Unleashes a wave of fire that burns and damages enemies in its path.",
        },
      },
      {
        image: FernixIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/E.mp4",
          title: "Arcane Bolt",
          description:
            "Fires a bolt of magical energy that pierces through enemies, dealing substantial damae.",
        },
      },

      {
        image: FernixIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/R.mp4",
          title: "Healing Flame",
          description:
            "Uses the power of fire to mend wounds and restore health over time.",
        },
      },
    ],
  },
  {
    image: FlamelordHero,
    title: "Ferno",
    subtitle: "Elemental Might Unleashed",
    description:
      "From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FernoCharactor,
    bgImage: FernoBg,
    SingleHeroIcons: [
      {
        image: FernoIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/Q.mp4",
          title: "Flame Roar",
          description:
            "Releases a fiery roar that creates a burning zone around enemies, damaging and repelling foes.",
        },
      },
      {
        image: FernoIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/W.mp4",
          title: "Magma Shot",
          description:
            "Fires a molten rock that burns and slows enemies, dealing damage over time.",
        },
      },
      {
        image: FernoIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/E.mp4",
          title: "Fireball",
          description:
            "Hurls a flaming projectile that explodes on impact, causing area damage and igniting enemies.",
        },
      },
      {
        image: FernoIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/R.mp4",
          title: "Inferno Recovery",
          description:
            "Absorbs heat from the surroundings to restore health, replenishing his vitality in the midst of battle.",
        },
      },
    ],
  },
  {
    image: FloraHero,
    title: "Flora",
    subtitle: "The Sylvan Enchantress",
    description:
      "In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of 'Guild of Heroes,' moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FloraCharactor,
    bgImage: FloraBg,
    SingleHeroIcons: [
      {
        image: FloraIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/Q.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
      {
        image: FloraIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/W.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },

      {
        image: FloraIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/E.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
      {
        image: FloraIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/R.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
    ],
  },
  {
    image: KalistaHero,
    title: "Kali",
    subtitle: "The Sanguimancer Sovereign",
    description:
      "In the hidden chambers of Arcanterra's forgotten crypts, Kalista, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: KaliCharactor,
    bgImage: KaliBg,
    SingleHeroIcons: [
      {
        image: KaliIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/Q.mp4",
          title: "Poison Bolt",
          description: "Fires a magical bolt that drains enemy health.",
        },
      },
      {
        image: KaliIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/W.mp4",
          title: "Ethereal Strike",
          description:
            "Delivers a piercing blow that deals damage and weakens enemies.",
        },
      },
      {
        image: KaliIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/E.mp4",
          title: "Dark Orb",
          description:
            "Sends a shadow orb that explodes on contact, causing area damage.",
        },
      },
      {
        image: KaliIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/R.mp4",
          title: "Lifeblood Restoration",
          description:
            "Uses absorbed life force to heal herself, replenishing her vitality.",
        },
      },
    ],
  },
  {
    image: LuminaHero,
    title: "Lumina",
    subtitle: "The Celestial Marksman",
    description:
      "In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky's dual nature, where the sun's clarity meets the moon's mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: LuminaCharactor,
    bgImage: LuminaBg,
    SingleHeroIcons: [
      {
        image: LuminaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/Q.mp4",
          title: "Arrow Shot",
          description:
            "Fires a precise arrow at her target, dealing high damage.",
        },
      },
      {
        image: LuminaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/W.mp4",
          title: "Frost Arrow",
          description:
            "Lumina fires an enchanted ice arrow that deals damage to enemies and inflicts a frostbite effect.",
        },
      },

      {
        image: LuminaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/E.mp4",
          title: "Mystic Arrow",
          description:
            "Launches an arrow that explodes on impact, causing area damage.",
        },
      },
      {
        image: LuminaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/R.mp4",
          title: "healing Light",
          description:
            "Channels a beam of light to restore her health and that of nearby allies, enhancing their durability.",
        },
      },
    ],
  },
  {
    image: Paladis,
    title: "Noble",
    subtitle: "The Time-Honored Sentinel",
    description:
      "In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: NobleCharactor,
    bgImage: NobleBg,
    SingleHeroIcons: [
      {
        image: NobleIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/Q.mp4",
          title: "Blazing Arc",
          description:
            "Sends a fiery arc of energy with a swing of his axe, burning and slashing foes.",
        },
      },
      {
        image: NobleIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/W.mp4",
          title: "Arcane Slash",
          description:
            "Strikes with a magical sword, dealing damage and releasing a burst of magical energy that affects enemies in its path.",
        },
      },

      {
        image: NobleIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/E.mp4",
          title: "Mystic Axe Throw",
          description:
            "Hurls a magical axe projectile that deals damage and explodes on impact, spreading arcane energy to nearby enemies.",
        },
      },
      {
        image: NobleIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/R.mp4",
          title: "Battle Mend",
          description:
            "Uses battle energy to heal his wounds over time, restoring his strength during combat.",
        },
      },
    ],
  },
  {
    image: RavenCrow,
    title: "Ravos",
    subtitle: "The Ethereal Warrior",
    description:
      "From the shadowed realms of Arcanterra, emerges Ravos, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravos wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: RavosCharactor,
    bgImage: RavosBg,
    SingleHeroIcons: [
      {
        image: RavosIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/Q.mp4",
          title: "Raven’s Talon",
          description:
            "Hurls a dark energy projectile that pierces through enemies, dealing damage, and leaving a shadowy mark that weaken.",
        },
      },

      {
        image: RavosIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/W.mp4",
          title: "Raven’s Descent",
          description:
            "The character swoops from above with a powerful strike, dealing heavy damage and creating a dark shockwave upon impact.",
        },
      },
      {
        image: RavosIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/E.mp4",
          title: "Bone Spear",
          description:
            "Launches a sharpened bone projectile that pierces and immobilizes foes.",
        },
      },
      {
        image: RavosIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/R.mp4",
          title: "Necrotic Regrowth",
          description:
            "Uses dark magic to mend bones and flesh, restoring health over time.",
        },
      },
    ],
  },
  {
    image: SeraphinaHero,
    title: "Seraphina",
    subtitle: "The Shadow's Sting",
    description:
      "In the quietest whispers of twilight, Seraphina, the Serpent Assassin of 'Guild of Heroes,' emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent's sinuous grace, she strikes with a cold precision known only to the shadows.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: SeraphinaCharactor,
    bgImage: SeraphinaBg,
    SingleHeroIcons: [
      {
        image: SeraphinaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/Q.mp4",
          title: "Venom Dart",
          description:
            "Fires a poisoned dart that deals damage over time, weakening enemies.",
        },
      },
      {
        image: SeraphinaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/W.mp4",
          title: "Seraphina’s Eclipse",
          description:
            "Strikes from above to create a circle of dark magic, damaging and weakening enemies within its radius.",
        },
      },
      {
        image: SeraphinaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/E.mp4",
          title: "Void Exhale",
          description:
            "Seraphina inhales deeply, gathering dark energy, and then exhales is as a projectile that damages enemies and reduces their defenses.",
        },
      },
      {
        image: SeraphinaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/R.mp4",
          title: "Serpent’s Renewal",
          description:
            "Sheds her skin to heal injuries and remove negative effects, restoring health.",
        },
      },
    ],
  },
  {
    image: HeroTwo,
    title: "Silverfang",
    subtitle: "Predator of the Shadows",
    description:
      "Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: SilverfangCharactor,
    bgImage: SilverfangBg,
    SingleHeroIcons: [
      {
        image: SilverfangIcon1,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/Q.mp4",
          title: "Ferocious Swipe",
          description:
            "Sends a wave of energy with a powerful swipe, knocking back and wounding foes in its path.",
        },
      },
      {
        image: SilverfangIcon2,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/W.mp4",
          title: "Lunar Claw",
          description:
            "The werewolf channels lunar energy into a powerful strike and leaving a glowing lunar mark that causes additional harm over time.",
        },
      },
      {
        image: SilverfangIcon3,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
          title: "Howling Roar",
          description:
            "Emits a fearsome roar that disorient and damages enemies, lowering their defenses and making them easier to defeat.",
        },
      },
      {
        image: SilverfangIcon4,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
          title: "Lunar Regeneration",
          description:
            "Harness moonlight energy to gradually heal wounds, restoring health over time.",
        },
      },
    ],
  },
  {
    image: Tauronis,
    title: "Tauronius",
    subtitle: "The Horned Vanguard",
    description:
      "In the wild expanses of Arcanterra where nature's call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: TauroniusCharactor,
    bgImage: TauroniusBg,
    SingleHeroIcons: [
      {
        image: TauroniusIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/Q.mp4",
          title: "Celestial Beam",
          description: "Sends forth a magic projectile that deals damage.",
        },
      },
      {
        image: TauroniusIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/W.mp4",
          title: "Earthshaker Stomps",
          description:
            "Stomps the ground to create a shockwave that damages and stuns enemies.",
        },
      },
      {
        image: TauroniusIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/E.mp4",
          title: "Nature’s Wrath",
          description:
            "Launches a burst of magical energy from his axes, striking multiple foes.",
        },
      },

      {
        image: TauroniusIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/R.mp4",
          title: "Forest’s Embrace",
          description:
            "Channels the power of the forest to heal his wounds over time, restoring vitality.",
        },
      },
    ],
  },
  {
    image: VoltClaw,
    title: "Zephyrion",
    subtitle: "The Storm’s Fury",
    description:
      "Amidst the heroes of Guild of Heroes, Zephyrion stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: ZephyrionCharactor,
    bgImage: ZephyrionBg,
    SingleHeroIcons: [
      {
        image: ZephyrionIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
          title: "Electric Spikes",
          description:
            "Fires electrified spikes from his forearm, piercing enemies and delivering a powerful electric shock wave.",
        },
      },
      {
        image: ZephyrionIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
          title: "Meteor Strike",
          description:
            "Calls down a massive asteroid that crashes into the ground, creating a powerful shockwave that deals heavy damage and disrupts enemies in the area.",
        },
      },

      {
        image: ZephyrionIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
          title: "Shock Fist",
          description:
            "Launches an electric punch that travels through the air, stunning and damaging enemies upon impact.",
        },
      },
      {
        image: ZephyrionIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
          title: "Electro Charge",
          description:
            "Absorbs ambient electricity to restore health over time, enhancing his vitality and resilience.",
        },
      },
    ],
  },
  {
    image: Mycoo,
    title: "Myco",
    subtitle: "The Spore Sentinel",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: MycoCharactor,
    bgImage: MycoBg,
    SingleHeroIcons: [
      {
        image: MycoIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/myco/Q.mp4",
          title: "Fungal Blast",
          description:
            "Launches a blast of spores that deals area of effect damage.",
        },
      },
      {
        image: MycoIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/myco/W.mp4",
          title: "Toxic Spores",
          description:
            "Releases a cloud of toxic spores that deal area of effect damage. The spores also cause a slight poison effect over time.",
        },
      },

      {
        image: MycoIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/myco/E.mp4",
          title: "Fungal Bloom",
          description:
            "Creates a large, explosion like effect that deals area of effect damage and infects enemies inside the radius.",
        },
      },
      {
        image: MycoIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/myco/R.mp4",
          title: "Vitality Leach",
          description:
            "Summons a beam of light that connects him to nature's life force and transferring it to heal himself.",
        },
      },
    ],
  },
  {
    image: Gorthar,
    title: "Gorthar",
    subtitle: "The Cursed Wildling",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: GortharCharactor,
    bgImage: GortharBg,
    SingleHeroIcons: [
      {
        image: GortharIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorthar/Q.mp4",
          title: "Savage Frenzy",
          description:
            "Channels his primal fury, striking enemies with rapid claw attacks.",
        },
      },
      {
        image: GortharIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorthar/W.mp4",
          title: "Doomclaw Rend: ",
          description:
            "Channels dark magic into his claws, delivering a devastating strike that rends the very soul of his target.",
        },
      },

      {
        image: GortharIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorthar/E.mp4",
          title: "Nature's Torment",
          description:
            "Summons the corrupted forces of nature to strike his foes and heal his own wounds.",
        },
      },
      {
        image: GortharIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorthar/R.mp4",
          title: "Cursed Howl",
          description:
            "Unleashes a terrifying roar, inflicting fear and weakening the enemies, heal himself",
        },
      },
    ],
  },
  // {
  //   image: Gorlock,
  //   title: "Gorlock",
  //   subtitle: "The Cursed Wildling",
  //   description:
  //     "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
  //   type: "Fighter",
  //   typeIcon: typeIcon,
  //   difficulity: "Moderate",
  //   abilities: "Abilities",
  //   SingleImg: SingleHerosImg,
  //   charactorImage: GorlockCharactor,
  //   bgImage: GorlockBg,
  //   SingleHeroIcons: [
  //     {
  //       image: GorlockIcon1,
  //       content: {
  //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorlock/Q.mp4",
  //         title: "Savage Frenzy",
  //         description:
  //           "Channels his primal fury, striking enemies with rapid claw attacks.",
  //       },
  //     },
  //     {
  //       image: GorlockIcon2,
  //       content: {
  //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorlock/W.mp4",
  //         title: "Doomclaw Rend: ",
  //         description:
  //           "Channels dark magic into his claws, delivering a devastating strike that rends the very soul of his target.",
  //       },
  //     },

  //     {
  //       image: GorlockIcon3,
  //       content: {
  //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorlock/E.mp4",
  //         title: "Nature's Torment",
  //         description:
  //           "Summons the corrupted forces of nature to strike his foes and heal his own wounds.",
  //       },
  //     },
  //     {
  //       image: GorlockIcon4,
  //       content: {
  //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/gorlock/R.mp4",
  //         title: "Cursed Howl",
  //         description:
  //           "Unleashes a terrifying roar, inflicting fear and weakening the enemies, heal himself",
  //       },
  //     },
  //   ],
  // },
  {
    image: Zephyr,
    title: "Zephyr",
    subtitle: "The Rare Blade",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: ZephyrCharactor,
    bgImage: ZephyrBg,
    SingleHeroIcons: [
      {
        image: ZephyrIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/zephyr/Q.mp4",
          title: "Phantom Strike",
          description:
            "Sends a magical slash through a targeted area, dealing burst damage to any enemies caught in its path.",
        },
      },
      {
        image: ZephyrIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/zephyr/W.mp4",
          title: "Shadow Veil: ",
          description:
            "Channels his shadow magic into a powerful blast, releasing it in a targeted location.",
        },
      },

      {
        image: ZephyrIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/zephyr/E.mp4",
          title: "Wind Shuriken",
          description:
            "Hurls a Wind Shuriken that slices through enemies, dealing damage.",
        },
      },
      {
        image: ZephyrIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/zephyr/R.mp4",
          title: "Shadow Veil",
          description:
            "Channels the power of the wind around him that heals himself.",
        },
      },
    ],
  },
  {
    image: Drakon,
    title: "Drakon",
    subtitle: "The Destroyer",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: DrakonCharactor,
    bgImage: DrakonBg,
    SingleHeroIcons: [
      {
        image: DrakonIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/drakon/Q.mp4",
          title: "Plasma Axe",
          description:
            "Hurls his enchanted axe, which is surrounded by crackling plasma energy. The axe slices through enemies with devastating force.",
        },
      },
      {
        image: DrakonIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/drakon/W.mp4",
          title: "Inferno Slash: ",
          description:
            "Swings his magic axe, igniting it with fiery energy. The powerful slash releases a wave of flames that scorches the ground.",
        },
      },

      {
        image: DrakonIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/drakon/E.mp4",
          title: "Reckless Charge",
          description:
            "Charges forward with his blazing axe, plowing through enemies, knocking them aside, and dealing heavy damage.",
        },
      },
      {
        image: DrakonIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/drakon/R.mp4",
          title: "Primal Surge",
          description:
            "Taps into ancient magic, channeling energy to rapidly heal his wounds and restore vitality.",
        },
      },
    ],
  },

  {
    image: Obsidian,
    title: "Obsidian",
    subtitle: "The Crystal Colossus",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: ObsidianCharactor,
    bgImage: ObsidianBg,
    SingleHeroIcons: [
      {
        image: ObsidianIcon11,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/obsidian/Q.mp4",
          title: "Crystal Shard Barrage",
          description:
            "Launches a rapid volley of sharp crystal shards that deal damage to all enemies in their path.",
        },
      },
      {
        image: ObsidianIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/obsidian/W.mp4",
          title: "Geomantic Apotheosis",
          description:
            "Channels the earth’s primal energy, releasing a massive earth projectile that deals significant damage and causes a localized quake on impact.",
        },
      },

      {
        image: ObsidianIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/obsidian/E.mp4",
          title: "Tectonic Shift",
          description:
            "Hurls a jagged, earth-infused projectile that, upon impact, triggers a seismic shockwave.",
        },
      },
      {
        image: ObsidianIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/obsidian/R.mp4",
          title: "Obsidian Armor",
          description:
            "Encases himself in a layer of dark, crystalline armor.",
        },
      },
    ],
  },

  {
    image: VENOM,
    title: "VENOM LORD",
    subtitle: "The Scaled Sovereign of Toxins",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: VENOMCharactor,
    bgImage: VENOMBg,
    SingleHeroIcons: [
      {
        image: VENOMIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/venom_lord/Q.mp4",
          title: "Toxic Lash",
          description:
            "Creates a trail of poison, continuing to damage and damage enemies who come into contact with it.",
        },
      },
      {
        image: VENOMIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/venom_lord/W.mp4",
          title: "Venomous Barrier ",
          description:
            "Creates a toxic shield that absorbs damage and create a magical circle that damages the enemies.",
        },
      },

      {
        image: VENOMIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/venom_lord/E.mp4",
          title: "Serpent Strike",
          description:
            "Shoots a poison projectile forward, stunning the damages first enemy hit.",
        },
      },
      {
        image: VENOMIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/venom_lord/R.mp4",
          title: "Sovereign's Venom",
          description:
            "Releases a cloud of potent toxins, to heal.",
        },
      },
    ],
  },

  {
    image: Aquarius,
    title: "Aquarius",
    subtitle: "The Eternal Tide",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: AquariusCharactor,
    bgImage: AquariusBg,
    SingleHeroIcons: [
      {
        image: AquariusIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/aquarius/Q.mp4",
          title: "Tidal Surge",
          description:
            "Launches a pressurized stream of water, damaging and pushing back the first enemy hit.",
        },
      },
      {
        image: AquariusIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/aquarius/W.mp4",
          title: "Whirlpool",
          description:
            "Creates a swirling vortex at target location, pulling enemies toward its center and dealing damage over time.",
        },
      },

      {
        image: AquariusIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/aquarius/E.mp4",
          title: "Twin Tides",
          description:
            "Launches two water projectiles that spiral around each other.",
        },
      },
      {
        image: AquariusIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/aquarius/R.mp4",
          title: "Celestial Surge",
          description:
            "Taps into cosmic energy, creating a localized field of rejuvenating light around himself. This field restores health instantly.",
        },
      },
    ],
  },
  {
    image: Mortalex,
    title: "Mortalex",
    subtitle: "The Soul Harvester",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: MortalexCharactor,
    bgImage: MortalexnBg,
    SingleHeroIcons: [
      {
        image: MortalexnIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
          title: "Infernal Chains",
          description:
            "Launches fiery chains in a line, dealing magic damage to the first enemy hit and rooting them.",
        },
      },
      {
        image: MortalexnIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
          title: "Soul Barrier",
          description:
            "Surrounds himself with a barrier of trapped souls absorbing damage,it deals magic damage to nearby enemies.",
        },
      },

      {
        image: MortalexnIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
          title: "Reaper's Grasp",
          description:
            "ortalex's next basic attack deals bonus magic damage and slows the target by  If the target dies while slowed.",
        },
      },
      {
        image: MortalexnIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
          title: "Doomsday Ritual",
          description:
            "Channels then curses an area. Enemies inside take magic damage per second and are gradually slowed up to 60%. At the end of the duration, enemies still in the area take 200/300/400 (+80% AP) magic damage and are stunned for 1.5 seconds.",
        },
      },
    ],
  },

  {
    image: Thornbark,
    title: "Thornbark",
    subtitle: "Guardian of the Ancient Grove",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: ThornbarkCharactor,
    bgImage: ThornbarkBg,
    SingleHeroIcons: [
      {
        image: ThornbarkIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/thornbark/Q.mp4",
          title: "Rootgrasp",
          description:
            "Summons roots from the ground to entangle and immobilize enemies.",
        },
      },
      {
        image: ThornbarkIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/thornbark/W.mp4",
          title: "Wrath of the Wild",
          description:
            "Channels the fury of nature, summoning a storm of thorny vines and razor-sharp leaves to tear through enemies.",
        },
      },

      {
        image: ThornbarkIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/thornbark/E.mp4",
          title: "Thorn Volley",
          description:
            "Fires a barrage of thorny projectiles in a straight line, piercing through enemies and dealing damage to all targets in their path.",
        },
      },
      {
        image: ThornbarkIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/thornbark/R.mp4",
          title: "Nature's Embrace",
          description:
            "alls upon the power of the forest to heal himself.",
        },
      },
    ],
  },

  
  {
    image: Korgath,
    title: "Korgath",
    subtitle: "The Infernal Warlord",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: KorgathCharactor,
    bgImage: KorgathBg,
    SingleHeroIcons: [
      {
        image: KorgathIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
          title: "Infernal Cleave",
          description:
            "Swings his weapon with immense force, unleashing a fiery arc that slices through enemies, leaving a trail of flames in its wake.",
        },
      },
      {
        image: KorgathIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
          title: "Demon Ascension",
          description:
            "Summons a powerful demonic effect that enhances his abilities and damages nearby enemies for a brief period.",
        },
      },

      {
        image: KorgathIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
          title: "Hell Fire Charge",
          description:
            "Channels up a ball of hellfire and throws it to a target area, causing an explosion on impact that deals damage.",
        },
      },
      {
        image: KorgathIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
          title: "Molten Enchantment",
          description:
            "Surrounds himself with a shimmering aura of molten energy, enhancing his defenses and helps healing.",
        },
      },
    ],
  },

  {
    image: Ursok,
    title: "Ursok",
    subtitle: "The Unbreakable",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: UrsokCharactor,
    bgImage: UrsokBg,
    SingleHeroIcons: [
      {
        image: UrsokIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/ursok/Q.mp4",
          title: "Frost Hammer",
          description:
            "Slams his massive hammer, dealing area damage and slowing enemies.",
        },
      },
      {
        image: UrsokIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/ursok/W.mp4",
          title: "Polar Embrace",
          description:
            "Gains a shield and heals nearby allies.",
        },
      },

      {
        image: UrsokIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/ursok/E.mp4",
          title: "Arctic Onslaught",
          description:
            "Charges forward, stunning the first enemy champion hit.",
        },
      },
      {
        image: UrsokIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/ursok/R.mp4",
          title: "Tundra's Wrath",
          description:
            "Unleashes the full power of the arctic, gaining bonus health and empowering his basic abilities.",
        },
      },
    ],
  },

  {
    image: Twinkle,
    title: "Twinkle",
    subtitle: "The Starlight",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: TwinkleCharactor,
    bgImage: TwinkleBg,
    SingleHeroIcons: [
      {
        image: TwinkleIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/twinkle/Q.mp4",
          title: "Chromatic Resonance",
          description:
            "Unleashes a burst of vibrant energy that swirls around her,  Enemies caught in the area of effect experience temporary disorientation.",
        },
      },
      {
        image: TwinkleIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/twinkle/W.mp4",
          title: "Spectrum Shift",
          description:
            "The surroundings transform into a psychedelic array of colors, creating a disruption for a short duration.",
        },
      },

      {
        image: TwinkleIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/twinkle/E.mp4",
          title: "Luminous Tether",
          description:
            "A slender beam of light connects the caster to a target.",
        },
      },
      {
        image: TwinkleIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/twinkle/R.mp4",
          title: "Celestial Cascade",
          description:
            "Unleashes a dazzling burst of starlight energy, creating a large area of swirling, healing constellations.",
        },
      },
    ],
  },

  {
    image: Grimvald,
    title: "Grimvald",
    subtitle: "The Eternal Warden",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: GrimvaldCharactor,
    bgImage: GrimvaldBg,
    SingleHeroIcons: [
      {
        image: GrimvaldIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/grimvald/Q.mp4",
          title: "Soul Slash",
          description:
            "Swings his blades with deadly precision, dealing a burst.",
        },
      },
      {
        image: GrimvaldIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/grimvald/W.mp4",
          title: "Reaper's Judgement",
          description:
            "Executes a powerful skyfall strike, bolstered by shadow power, dealing area-of-effect damage and leaving a shadow-infused zone.",
        },
      },

      {
        image: GrimvaldIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/grimvald/E.mp4",
          title: "Ethernal Slash",
          description:
            "Inflicts a strong, empowered slash, causing immediate damage to the main target.",
        },
      },
      {
        image: GrimvaldIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/grimvald/R.mp4",
          title: "Spectral Chains",
          description:
            "Channels shadowy energy over a circle around himself to heal.",
        },
      },
    ],
  },

  {
    image: Amelia,
    title: "Amelia",
    subtitle: "The Unbreakable",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: AmeliaCharactor,
    bgImage: AmeliaBg,
    SingleHeroIcons: [
      {
        image: AmeliaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/amelia/Q.mp4",
          title: "Crimson Slash",
          description:
            "Slashes with shadow-infused weapon, dealing damage.",
        },
      },
      {
        image: AmeliaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/amelia/W.mp4",
          title: "Shadow Essence",
          description:
            "Releases a swirling mass of dark energy that seeks out enemies, dealing damage over time and passes through them.",
        },
      },

      {
        image: AmeliaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/amelia/E.mp4",
          title: "Soul Siphon",
          description:
            "Creates a tether between herself and a target enemy champion While active, she drains  health  from the target and gains 20% movement speed.",
        },
      },
      {
        image: AmeliaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/amelia/R.mp4",
          title: "Realm of Shadows",
          description:
            "Unleashes her full power, shrouding the area in darkness few seconds.",
        },
      },
    ],
  },

  {
    image: Pyrovine,
    title: "Pyrovine",
    subtitle: "The Scorching Druid",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: PyrovineCharactor,
    bgImage: PyrovineBg,
    SingleHeroIcons: [
      {
        image: PyrovineIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/pyrovine/Q.mp4",
          title: "Blazing Thorns",
          description:
            "Launches a volley of burning thorns, damaging and burning enemies.",
        },
      },
      {
        image: PyrovineIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/pyrovine/W.mp4",
          title: "Wildfire Barrier",
          description:
            "Creates a shield of burning vines, absorbing damage and damaging nearby enemies.",
        },
      },

      {
        image: PyrovineIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/pyrovine/E.mp4",
          title: "Ember Dash",
          description:
            "Pyrovine dashes, leaving a trail of fire that damages enemies and speeds up allies.",
        },
      },
      {
        image: PyrovineIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/pyrovine/R.mp4",
          title: "Infernal Bloom",
          description:
            "Grows massive flaming flowers around,heal himself.",
        },
      },
    ],
  },
  
  {
    image: Sylvan,
    title: "Sylvan",
    subtitle: "The Earth Colossus",
    description:
      "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: SylvanCharactor,
    bgImage: SylvanBg,
    SingleHeroIcons: [
      {
        image: SylvanIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/sylvan/Q.mp4",
          title: "Tectonic Slam",
          description:
            "Sylvan smashes the ground, creating a shockwave that damages and slows enemies.",
        },
      },
      {
        image: SylvanIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/sylvan/W.mp4",
          title: "Verdant Eruption",
          description:
            "Causes the ground to burst  damaging and briefly knocking up enemies in the area.",
        },
      },

      {
        image: SylvanIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/sylvan/E.mp4",
          title: "Earthshard",
          description:
            "Hurls a chunk of earth infused with natural energy, dealing  damages.",
        },
      },
      {
        image: SylvanIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/sylvan/R.mp4",
          title: "Primordial Awakening",
          description:
            "Unleashes ancient forest energy, healing allies and damaging enemies in a targeted area.",
        },
      },
    ],
  },

];