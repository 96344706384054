import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Button from "../../../Components/Dbutton";
import DtaskCard from "../../../Components/DtaskCard";
import CompleteModel from "./completeModel";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetCompletedSocialTaskMutation } from "../../../services/socialTask";
import HeroModel from "./heroModel";
import DailyTasksModel from "../../../Components/DailyTasksModel";

const DcompleteTask = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isHeroModelOpen, setIsHeroModelOpen] = useState(false);
  const [isStepsModal, setIsStepsModal] = useState(false);

  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { completedSocialTasks } = useSelector((state) => state.socialTask);
  const [getAllTasks, { isLoading }] = useGetCompletedSocialTaskMutation();

  const fetchUserTask = useCallback(
    async (id) => {
      try {
        await getAllTasks(id).unwrap();
      } catch (err) {
        toast.error(err?.message);
      }
    },
    [getAllTasks]
  );

  useEffect(() => {
    if (user) {
      fetchUserTask(user?._id);
    }
  }, [user, fetchUserTask]);

  const onCheck = (selectedItem) => {
    setIsModelOpen(false);
    window.open(selectedItem?.buttonLink, "_blank");
  };

  const filteredTasks = completedSocialTasks || [];

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>Completed tasks</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() => {
              setIsStepsModal(true)
            }}
          />
        </div>

        {filteredTasks.length === 0 && isLoading && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}
        {filteredTasks.length === 0 && !isLoading ? (
          <div>You don't have any completed tasks yet</div>
        ) : null}

        <div className="d-task-list">
          {filteredTasks?.map((item, index) => {
            const heroImg = item?.characterDetails?.avatar;
            const isHeroType = item?.socialTask?.type === "Choose-your-hero";
            return (
              <DtaskCard
                data={{ ...item, ...item.socialTask }}
                key={index}
                isHeroSelected={isHeroType && heroImg}
                style={{
                  backgroundImage:
                    isHeroType && heroImg ? `url(${heroImg})` : "",
                  backgroundSize: "cover",
                  backgroundPosition: "top center",
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => {
                  setIsDataId(item._id);
                  if (item.socialTask?.isHero) {
                    setIsHeroModelOpen(true);
                    return;
                  }
                  setIsModelOpen(true);
                }}
                isStatusShow
              />
            );
          })}
        </div>
      </div>
      {user && completedSocialTasks.length > 0 ? (
        <CompleteModel
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          data={completedSocialTasks}
          id={isDataId}
          onCheck={onCheck}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}

      <DailyTasksModel
        isActive={isStepsModal}
        onClose={() => setIsStepsModal(false)}
        activeModel="stepone"
      />

      {isHeroModelOpen && (
        <HeroModel
          onClose={() => {
            setIsHeroModelOpen(false);
            setIsDataId();
          }}
          isActive={isHeroModelOpen}
        />
      )}
    </>
  );
};

export default DcompleteTask;
