import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { playSound } from "../utils/helper";
import { useSelector } from "react-redux";

const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

  const [isIframeFocused, setIsIframeFocused] = useState(false);
  const [isExternalPath, setIsExternalPath] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [pendingPath, setPendingPath] = useState(null);
  const [isSidebarModelOpen, setIsSidebarModelOpen] = useState(false);

  // Function to handle navigation globally
  const handleNavigation =
    (path, isExternalLink = false) =>
    (event) => {
      playSound(MenuChangeSound);
      setIsExternalPath(isExternalLink);

      // ✅ Check if user is null and path is "/play-minigame"
      if (!user && path === "/play-minigame") {
        event.preventDefault();
        setIsSidebarModelOpen(true);
        console.log("User is not logged in. Sidebar model opened.");
        return;
      }

      // ✅ Handle iframe focus restriction
      if (isIframeFocused) {
        event.preventDefault();
        setPendingPath(path);
        setShowConfirmationModal(true);
      } else {
        setShowConfirmationModal(false);

        // ✅ Open in new tab or navigate normally
        isExternalLink ? window.open(path, "_blank") : navigate(path);
      }
    };

  return (
    <NavigationContext.Provider
      value={{
        isIframeFocused,
        setIsIframeFocused,
        showConfirmationModal,
        setShowConfirmationModal,
        handleNavigation,
        pendingPath,
        isExternalPath,
        setPendingPath,
        isSidebarModelOpen,
        setIsSidebarModelOpen,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

// Custom Hook for easy access
export const useNavigation = () => useContext(NavigationContext);
