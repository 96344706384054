import { useEffect, useState } from "react";
import Button from "../Dbutton";
import Dmodel from "../Dmodel";
import { useLocation } from "react-router-dom";

const DloginModal = ({ isActive, onClose, description, closeDisabled= false, isClose=true }) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isSignupLoading, setIsSignupLoading] = useState(false);
  const { pathname } = useLocation();
  const loginLink = process.env.REACT_APP_LOGIN_URL;
  const singupLink = process.env.REACT_APP_SIGNUP_URL;

  useEffect(() => {
    setIsSignupLoading(false);
    setIsLoginLoading(false);
  }, [pathname]);

  return (
    <>
      <Dmodel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
        extraClasses="login-modal"
        closeDisabled={closeDisabled}
        isClose={isClose}
      >
        <div className="d-model-text wel-modal">
          <h3>welcome to Guild of heroes!</h3>
          <p>
            {description ||
              "Before you can start completing tasks to earn GOH COINS, you need an account with Guild of Heroes"}
          </p>
          <div className="flex">
            <h5>First Time Joining Us?</h5>
            <Button
              variant="primary-contained"
              text="create account"
              onClick={() => {
                setIsSignupLoading(true);
                window.location.href = singupLink;
              }}
              isLoading={isSignupLoading}
            />
          </div>
          <div className="flex">
            <h5>Returning Hero?</h5>
            <Button
              variant="secondary-contained"
              text="Login"
              onClick={() => {
                setIsLoginLoading(true);
                window.location.href = loginLink;
              }}
              isLoading={isLoginLoading}
            />
          </div>
        </div>
      </Dmodel>
    </>
  );
};

export default DloginModal;
