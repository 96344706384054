import React from "react";
import Explore from "../../../Components/Explore";
const mapVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/map-mastery.mp4`;
const RedPoster = `${process.env.REACT_APP_S3_BUCKET}/Images/Blinke-Strike-poster.png`;
const RedTeamImg = `${process.env.REACT_APP_S3_BUCKET}/Images/red-team.jpg`;

const RedTeam = {
  video: mapVideo,
  poster: RedPoster,
  title: "Game Objective",
  tagline: "Seize Control",
  desc: "In the strategic world of Guild of Heroes, the ultimate goal is to lead your team to victory by outsmarting the opposition. Command a lineup of diverse heroes, each with unique abilities, to engage in tactical battles. The key to success lies in demolishing the enemy's defenses and ultimately their nexus, while defending your own. Master resource control, dominate the battlefield, and become the champion of Arcanterra.",
  backgroundImg: `url(${RedTeamImg})`,
  autoPlay: true,
  className: "rewamp-space PlayReverse",
};

function GameObjective() {
  return (
    <>
      <Explore {...RedTeam} />
    </>
  );
}

export default GameObjective;
