import React from "react";
import Banner from "./Banner";
import ChooseHeroSection from "./ChooseHeroSection";
import HowToPlay from "./HowToPlay";
import LatestNews from "./LatestNews";
import GuildAccessSection from "./GuildAccessSection";
import OurAlliances from "./OurAlliances";
import GameObjective from "./GameObjective";
import DevelopmentRoadMap from "./DevelopmentRoadMap";
import HeroInfo from "./HeroInfo";
import "./style.css";
import Trailer from "./Trailer";

const Home = () => {
  return (
    <div id="mainwrapper" className="homepage ">
      <Banner />
      <LatestNews />
      <Trailer />
      <HowToPlay />
      <GameObjective />
      <ChooseHeroSection />
      <GuildAccessSection />
      <OurAlliances />
      <DevelopmentRoadMap />
      <HeroInfo />
    </div>
  );
};

export default Home;
