const TextInput = ({ extraClasses, onChange, value, rightNode, ...rest }) => {
  return (
    <div className="input-field">
      <input
        {...rest}
        value={value}
        className={`${extraClasses ? extraClasses : ""}`}
        onChange={onChange}
      />
      {rightNode ? rightNode : ""}
    </div>
  );
};

export default TextInput;
