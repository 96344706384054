import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/parallax";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";
import Button from "../../../../Components/Dbutton";
import DcardBox from "../../../../Components/DcardBox";
import { currencyFormat, playSound } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCharactersMutation,
  useSelectCharactorMutation,
} from "../../../../services/character";
import { toast } from "react-toastify";
import { chooseYourHero } from "../../../../store/slices/character";
import { createEvent, getEventType } from "../../../../utils/event";
import { setUserCoins } from "../../../../store/slices/authSlice";
import Dtoast from "../../../../Components/Dtoast";

const HeroChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/change_hero_v2.wav`;
const HeroConfirmSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/confirm_v1.wav`;

const SelectYourCharacter = ({ data, onConfirm }) => {
  const [selectedCharacter, setSelectedCharacter] = useState("");
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { characters } = useSelector((state) => state.character);

  const [getCharacters, { isLoading }] = useGetCharactersMutation();
  const [chooseCharacter] = useSelectCharactorMutation();

  console.log("data", data);
  useEffect(() => {
    try {
      getCharacters();
    } catch (err) {
      toast.log(err?.message);
    }
  }, [getCharacters]);

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.realIndex;
    setSelectedCharacter(characters[currentIndex]);
    playSound(HeroChangeSound);
  };

  const handleConfirm = async () => {
    if (selectedCharacter) {
      const reqBody = {
        charcterId: selectedCharacter?._id,
        userId: user?._id,
        socialTaskId: data?._id,
      };
      try {
        await chooseCharacter(reqBody);
        dispatch(chooseYourHero(selectedCharacter));
        dispatch(setUserCoins(5000));
        createEvent(getEventType("Choose-your-hero"), 5000);
        playSound(HeroConfirmSound);
        onConfirm();
      } catch (err) {
        toast.error(err?.message);
      }
    }
  };

  useEffect(() => {
    if (!selectedCharacter) return;
    playSound(HeroChangeSound);
  }, [selectedCharacter]);
  return (
    <div className="d-model-text">
      <h3>choose your hero</h3>
      <h4>
        {"+"}
        {currencyFormat(Number(data?.coins))}
      </h4>
      <p>
        By selecting <span>{selectedCharacter?.name}</span> upgraded skin. You
        will enter the lottery for a chance to win this as an NFT after the
        airdrop.
      </p>
      {characters.length === 0 && isLoading ? (
        <div
          style={{
            height: "220px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="heros-image-slider">
          <Swiper
            effect="creative"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            spaceBetween={10}
            parallax={true}
            pagination={{ clickable: true }}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            onSlideChange={handleSlideChange}
          >
            {characters.map((item, index) => (
              <SwiperSlide key={index}>
                <DcardBox
                  variant="secondary-contained"
                  extraClasses="d-image-slider-card"
                  key={index}
                >
                  <img src={item.avatar} alt="Hero Img" />
                </DcardBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <Button
        variant="primary-contained"
        text="confirm"
        extraClasses="w-full"
        onClick={handleConfirm}
        disabled={isLoading}
      />

      {showToast && (
        <Dtoast
          setIsSuccess={setShowToast}
          isSuccess={showToast}
          message={`You recieved ${5000} GOH Gold! Keep collecting to climb the ranks!`}
        />
      )}
    </div>
  );
};

export default SelectYourCharacter;
