import React from "react";
// import Slider from "react-slick";
const Square = `${process.env.REACT_APP_S3_BUCKET}/Images/square.svg`;
const UpcomingImg = `${process.env.REACT_APP_S3_BUCKET}/Images/up-coming-img.png`;

const RoadMapList = [
  {
    image: Square,
    altText: "Square",
    class: "active",
    title: "Q4 2022",
    subtitle: "Development Initiation",
    desc: `Start game development focusing on foundational aspects.`,
    list: [
      "Project Kickoff:",
      "Conceptualization and initial planning.",
      "Team formation and assignment of roles.",
      "Establishment of project goals and milestones.",
      "Research and selection of Unity as the game development engine.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    class: "active",
    title: "Q1 2023",
    subtitle: "Foundation Development",
    desc: `Release beta version to gather player feedback.`,
    list: [
      "Core Mechanics and Systems:",
      "Development of core game mechanics using Unity.",
      "Implementation of basic gameplay systems (movement, combat, abilities).",
      "Initial design of game world and environment.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q2 2023",
    subtitle: "Prototype and Early Testing",
    desc: `Raise capital from VCs and through IDO platforms.`,
    list: [
      "Alpha Version Development",
      "Creation of the first playable prototype.",
      "Internal testing and feedback collection.",
      "Iterative improvements based on feedback.",
      "Implementation of basic AI and NPC behavior.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q3 2023",
    subtitle: "Feature Expansion",
    desc: `Launch in-game and external marketplaces for trading assets.`,
    list: [
      "Advanced Mechanics and Systems:",
      "Addition of advanced gameplay features (quests, leveling, itemization).",
      "Development of multiplayer functionality and matchmaking systems.",
      "Expansion of game world with new areas and environments.",
      "Implementation of more complex character models and animations.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q4 2023",
    subtitle: "Beta Version and Closed Testing",
    desc: `Final adjustments and preparations for the official launch.`,
    list: [
      "Beta Version Release:",
      "Release of beta version for closed testing.",
      "Collection of extensive feedback from testers.",
      "Debugging and performance optimization.",
      "Implementation of additional characters and abilities.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q1 2024",
    subtitle: "Content Creation and Refinement",
    desc: `Release the full game.`,
    list: [
      "Content Expansion:",
      "Development of new quests, missions, and storylines.",
      "Implementation of additional game modes and features.",
      "Refinement of user interface and user experience.",
      "Creation of advanced AI behavior and complex NPC interactions.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q2 2024",
    subtitle: "Public Beta and Marketing",
    desc: `Release the full game.`,
    list: [
      "Public Beta Launch:",
      "Release of public beta version.",
      "Launch of marketing and promotional campaigns.",
      "Collection of feedback from a larger player base.",
      "Continuous updates and improvements based on feedback.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "Q3 2024",
    subtitle: "Beta Launch and GOH Coins Earning",
    desc: `Release the full game.`,
    list: [
      "Beta Launch",
      "Launch of beta version for broader audience.",
      "Implementation of features allowing players to earn GOH COINS.",
      "Integration of GOH COINS earning through gameplay and tasks.",
      "Reflection of earned GOH COINS in airdrops and the Telegram mini app.",
    ],
  },
  {
    image: Square,
    altText: "Square",
    title: "UPCOMING",
    subtitle: "",
    imgUrl: UpcomingImg,
    desc: ``,
    list: [
      "Enhanced crowd control mechanics.",
      "Refined last-hitting mechanics.",
      "Robust respawn system.",
      "Fog of war for dynamic gameplay.",
      "Improved heads-up display.",
      "Live game spectating features.",
      "Sophisticated matchmaking system.",
      "Detailed ranking tiers and divisions.",
      "Comprehensive friends list system.",
      "Champion skins system.",
      "Database of over 300+ items.",
      "Shop for in-game items.",
      "Game client for easy access and navigation.",
      "Enhanced combat mechanics.",
      "Social features like in-game chat and friend invitations.",
      "Optimized server performance.",
      "Various game modes for diverse experiences.",
      "Support for Apple and mobile users.",
      "Launch of Airdrop Campaign"
    ],
  },
];

function DevelopmentRoadMap() {
  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1025,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <section className="roadmap">
      <div className="road-map-slide container">
        <h2 className="h2head center">
          Development <br /> Roadmap
        </h2>
        <p className="center">
          Explore 'Guild of Heroes' with our roadmap, showcasing upcoming
          updates and expansions. Brace for a trail of continuous innovation and
          engaging adventures!
        </p>

        <div className="roadmap-box">
          {RoadMapList.map((item, index) => (
            <div className="roadmap-item" key={index}>
              <div className="roadmap-question">
                <h3>{item.title}</h3>
                <p>{item.subtitle}</p>
                {item.imgUrl && <img src={item.imgUrl} width={'90%'} alt="img" />}
              </div>
              <div className="roadmap-list">
                <ul>
                  {item.list.map((listItem, ind) => (
                    <li key={ind}>{listItem}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="road-item-list">
          <Slider {...settings}>
            {RoadMapList.map((item, index) => {
              return (
                <div
                  className={`map-item ${item?.class ? "active" : ""}`}
                  key={index}
                >
                  <h4>{item?.title}</h4>
                  <div className="roadmap-img">
                    <img src={item?.image} alt={item?.altText} />
                  </div>
                  {item?.subtitle && <h4>{item?.subtitle}</h4>}
                  <p>{item?.desc}</p>
                </div>
              );
            })}
          </Slider>
        </div> */}
      </div>
    </section>
  );
}

export default DevelopmentRoadMap;
