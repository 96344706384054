import VideoSection from "./videos-section";
import WelcomeBanner from "./banner";
import WelcomeFooter from "./footer";
import "./style.css";

const WelComeHeroe = () => {
  return (
    <div id="mainwrapper" className="wel-hero-come ">
      <WelcomeBanner />
      <VideoSection />
      <WelcomeFooter />
    </div>
  );
};

export default WelComeHeroe;
