import React from "react";

const WelcomeFooter = () => {
  return (
    <div className="wel-footer">
      <div className="container">
        <p>nxentertainment.com</p>
      </div>
    </div>
  );
};

export default WelcomeFooter;
