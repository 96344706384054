import React, { useState } from "react";
import Loader from "../../Components/Loader";
const logo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/welcome-logo.svg`;

const WelcomeBanner = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  return (
    <div className="wel-banner">
      <div
        className="container"
        style={{ display: isVideoLoaded ? "flex" : "none" }}
      >
        <div className="welcome-logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      {!isVideoLoaded && <Loader />}
      <section className="topbanner">
        <div
          className="container"
          style={{ display: isVideoLoaded ? "flex" : "none" }}
        >
          <h1>welcome to guild of heroes</h1>
          <p>Your journey begins here </p>
          <p>Choose your adventure and claim exclusive rewards</p>
        </div>
        <video
          autoPlay
          loop
          muted
          width="100%"
          playsInline
          onLoadedMetadata={() => setIsVideoLoaded(true)}
        >
          <source
            src={`${process.env.REACT_APP_S3_BUCKET}/videos/WebBannerNew_new.mp4`}
            type="video/mp4"
          />
        </video>
      </section>
    </div>
  );
};

export default WelcomeBanner;
