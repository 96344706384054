import "./App.css";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import { AuthProvider } from "./Context/AuthContext";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { arbitrum, mainnet } from "viem/chains";
import { ConfigProvider } from "antd";
import Routes from "./Routes";
import { useGetUserByIdMutation } from "./services/user";
import { logout, setToken } from "./store/slices/authSlice";
import Loader from "./Components/Loader";
import {
  incrementPoints,
  setCurrentPoints,
  setTotalPoints,
} from "./store/slices/pointSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  connectSocket,
  disconnectSocket,
  subscribeToOnlineUsers,
} from "./services/socket";
import { setOnlineUsersCount } from "./store/slices/userSlice";
import { WalletContextProvider } from "./Context/walletContext";
import { NavigationProvider } from "./Context/NavigationContext";
// Project Id of web 3 modal
const projectId = process.env.REACT_APP_WEB3_PROJECT_ID;

const metadata = {
  name: "Guild of Heroes",
};

// Setting config for web3modal
const chains = [mainnet, arbitrum];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableAnalytics: true,
});

// Creating web3modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    "--w3m-accent": "#d0a755",
    "--w3m-border-radius-master": "1px",
  },
  themeMode: "dark",
});

function App() {
  const [active, setActive] = useState(false);
  const [searchParam] = useSearchParams();
  const paramId = searchParam.get("id");
  const paramToken = searchParam.get("token");
  const paramEntityToken = searchParam.get("entityToken");
  const paramJwtToken = searchParam.get("jwtToken");
  const dispatch = useDispatch();
  const [getUserById, { isLoading }] = useGetUserByIdMutation();
  const { currentPoints, totalPoints } = useSelector((state) => state.points);
  const { user } = useSelector((state) => state.auth);
  const socketRef = useRef(null);

  useEffect(() => {
    // Load points from localStorage or initialize
    const savedPoints = localStorage.getItem("currentPoints");
    if (savedPoints) {
      const { value, total } = JSON.parse(savedPoints);
      dispatch(setCurrentPoints(value));
      dispatch(setTotalPoints(total));
    } else {
      dispatch(setTotalPoints(3000));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem(
      "currentPoints",
      JSON.stringify({ value: currentPoints, total: totalPoints })
    );
  }, [currentPoints, totalPoints]);

  useEffect(() => {
    // Increase points over time when not clicked
    const interval = setInterval(() => {
      if (currentPoints < totalPoints) {
        dispatch(incrementPoints(1));
      }
    }, 500);

    return () => clearInterval(interval);
  }, [currentPoints, totalPoints, dispatch]);

  // login user from params or local storage
  useEffect(() => {
    const storeTokens = (id, token, entityToken, jwtToken) => {
      if (id && token && entityToken && jwtToken) {
        localStorage.setItem("token", token);
        localStorage.setItem("userId", id);
        localStorage.setItem("entityToken", entityToken);
        localStorage.setItem("jwtToken", jwtToken);

        dispatch(setToken({ token, jwtToken }));
        getUserById(id);

        window.PlayFab._internalSettings.entityToken = entityToken;
        window.PlayFab._internalSettings.sessionTicket = token;
        window.PlayFab.settings.titleId =
          process.env.REACT_APP_PLAYFAB_TITLE_ID;
        window.PlayFab.settings.developerSecretKey =
          process.env.REACT_APP_PLAYFAB_SECRET_KEY;
      }
    };

    const applyStoredTokens = (token, userId, entityToken, jwtToken) => {
      dispatch(setToken({ token, jwtToken }));
      getUserById(userId);

      window.PlayFab._internalSettings.entityToken = entityToken;
      window.PlayFab._internalSettings.sessionTicket = token;
      window.PlayFab.settings.titleId = process.env.REACT_APP_PLAYFAB_TITLE_ID;
      window.PlayFab.settings.developerSecretKey =
        process.env.REACT_APP_PLAYFAB_SECRET_KEY;
    };

    const clearAndStoreTokens = (id, token, entityToken, jwtToken) => {
      localStorage.clear();
      storeTokens(id, token, entityToken, jwtToken);
    };

    // Fetch stored tokens from localStorage
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const entityToken = localStorage.getItem("entityToken");
    const jwtToken = localStorage.getItem("jwtToken");

    // Decode URL parameters if they exist
    const decodedParamId = paramId ? decodeURIComponent(paramId) : null;
    const decodedParamToken = paramToken
      ? decodeURIComponent(paramToken)
      : null;
    const decodedParamEntityToken = paramEntityToken
      ? decodeURIComponent(paramEntityToken)
      : null;
    const decodedParamJwtToken = paramJwtToken
      ? decodeURIComponent(paramJwtToken)
      : null;

    // Handle logic based on URL parameters and localStorage values
    if (
      decodedParamId &&
      decodedParamToken &&
      decodedParamEntityToken &&
      decodedParamJwtToken
    ) {
      const paramsChanged =
        token !== decodedParamToken ||
        userId !== decodedParamId ||
        entityToken !== decodedParamEntityToken ||
        jwtToken !== decodedParamJwtToken;

      if (paramsChanged) {
        clearAndStoreTokens(
          decodedParamId,
          decodedParamToken,
          decodedParamEntityToken,
          decodedParamJwtToken
        );
      } else {
        storeTokens(
          decodedParamId,
          decodedParamToken,
          decodedParamEntityToken,
          decodedParamJwtToken
        );
      }
    } else if (token && userId && entityToken && jwtToken) {
      applyStoredTokens(token, userId, entityToken, jwtToken);
    } else {
      dispatch(logout());
    }
  }, [
    paramId,
    paramToken,
    paramEntityToken,
    dispatch,
    getUserById,
    paramJwtToken,
  ]);

  useEffect(() => {
    if (!socketRef.current && user) {
      socketRef.current = connectSocket(user?.playFabId);
      subscribeToOnlineUsers((users) => {
        dispatch(setOnlineUsersCount(users));
      });
    } else {
      disconnectSocket();
      socketRef.current = null;
    }
  }, [user, dispatch]);

  // If data is loading, show the loader
  if (isLoading) return <Loader />;

  // Focus on the active menu
  const handleClick = (event) => {
    setActive((current) => !current);
  };

  return (
    <div className={`wrapper ${active ? "fixed-wrap" : ""}`}>
      {/* Ant design config */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#d0a755",
            colorTextPlaceholder: "##EDE5CE",
          },
          components: {
            Progress: {
              circleTextColor: "#EDE5CE",
              defaultColor: "#d0a755",
              fontFamily: "Rakkas",
              colorSuccess: "#d0a755",
            },
            DatePicker: {
              activeBg: "#d0a755",
            },
          },
        }}
      >
        <WagmiConfig config={wagmiConfig}>
          <WalletContextProvider>
            <NavigationProvider>
              <AuthProvider>
                <ScrollToTop />
                <div className="maindiv">
                  <Routes active={active} handleClick={handleClick} />
                </div>
              </AuthProvider>
            </NavigationProvider>
          </WalletContextProvider>
        </WagmiConfig>
      </ConfigProvider>
    </div>
  );
}

export default App;
