import { useEffect } from "react";
import "./style.css";
import { playSound } from "../../utils/helper";
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;
const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`; 

const Dmodel = ({
  children,
  steps,
  isActive,
  isCurrentStep,
  onClose,
  extraClasses,
  isClose = true,
  closeDisabled = false,
}) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add("d-wraper-modal-open");
    } else if (!isActive) {
      document.body.classList.remove("d-wraper-modal-open");
    }

    return () => {
      document.body.classList.remove("d-wraper-modal-open");
    };
  }, [isActive]);

  return (
    <div
      className={`d-model ${isActive ? "active-model" : ""} ${extraClasses ? extraClasses : ""
        }`}
    >
      <div className="d-model-overlay"  onClick={!closeDisabled ? onClose : undefined} />
      <div className="d-model-content">
        <div className="d-model-header">
          <ul className="d-model-steps">
            {steps && (
              <>
                {Array.from({ length: steps }).map((_, index) => {
                  return (
                    <li
                      key={index}
                      className={isCurrentStep === index ? "active-step" : ""}
                    ></li>
                  );
                })}
              </>
            )}
          </ul>
          {isClose && (
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => {
                onClose();
                playSound(MenuChangeSound);
              }}
            />
          )}
        </div>
        <div className="d-model-main" style={{ padding: !isClose ? "0" : "" }}>
          {children}
        </div>
      </div>

    </div>
  );
};

export default Dmodel;
