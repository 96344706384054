import { useNavigate } from "react-router-dom";
import Button from "../../Components/Dbutton";

const Poster = `${process.env.REACT_APP_S3_BUCKET}/Images/ball-lightning-poster-new.png`;

const leftVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/landing-left-video-v3.mp4`;
const rightVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/landing-right-video-v3.mp4`;

const WelcomeMocks = [
  {
    video: leftVideo,
    title: "Access the full game beta",
    desc: "Exclusive Beta Access for Partner",
    tagline: "(eligible for airdrop)",
    videoLabel: "13,000 heroes registered | 100 currently battling",
    buttonOneText: "Play Now",
    buttonOneLink: "/",
    buttonTwoText: "Learn More",
    buttonTwoLink: "/",
    disabled: true,
  },
  {
    video: rightVideo,
    title: "play the mini-game instantly",
    desc: "",
    tagline: "Eligible for Airdrop",
    videoLabel: "13,000 heroes registered | 100 currently battling",
    buttonOneText: "start playing",
    buttonOneLink: "/play-minigame",
    buttonTwoText: "find out More",
    buttonTwoLink: "/home",
  },
];

const VideoSection = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-intro">
      <div className="container">
        <div className="row">
          {WelcomeMocks.map((item) => {
            return (
              <div className="col-2">
                <h3 className={item.disabled ? "dot" : ""}>{item.title}</h3>
                <p>
                  {item.desc}
                  <div className="green">{item.tagline}</div>
                </p>
                <span>{item.videoLabel}</span>
                <div className="wel-video">
                  <video
                    poster={Poster}
                    autoPlay
                    loop
                    muted
                    controls={false}
                    playsInline
                  >
                    <source src={item.video} type="video/mp4" />
                  </video>
                </div>
                <div className="flex">
                  <Button
                    variant="primary-contained"
                    text={item.buttonOneText}
                    extraClasses="w-full"
                    onClick={() => navigate(`${item.buttonOneLink}`)}
                    disabled={item.disabled}
                  />
                  <Button
                    variant="secondary-contained"
                    text={item.buttonTwoText}
                    extraClasses="w-full"
                    onClick={() => navigate(`${item.buttonTwoLink}`)}
                    disabled={item.disabled}
                  />
                </div>
              </div>
            );
          })}
        
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
