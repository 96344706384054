import React from "react";
import Explore from "../../../Components/Explore";
const MarketImg = `${process.env.REACT_APP_S3_BUCKET}/Images/marketplace.jpg`;
const bgImg = `${process.env.REACT_APP_S3_BUCKET}/Images/bg31.png`;
const accessSectionVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/guild-access-video.mp4`;
const masterSectionVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/master-the-battle-v2.mov`;

const MarketPlace = {
  video: accessSectionVideo,
  autoPlay: true,
  title: "Guild Access",
  shortDesc: `Can’t meet the desktop version’s system requirements? No worries! Experience Guild of Heroes through the browser version, a simplified glimpse of the full game. Even with fewer features and lower specs, it still offers plenty of fun and, most importantly, lets you earn Season 1 airdrop points just like in the full game.`,
  backgroundImg: `url(${MarketImg})`,
  buttonText:'PLAY MINIGAME',
  buttonLink:'/play-minigame',
  className: "",
};

const PlayList = {
  video: masterSectionVideo,
  autoPlay: true,
  title: "Master the Battle",
  tagline: "Ready To Become A Hero?",
  className: "PlayReverse",
  desc: "Discover the strategies, skills, and secrets to success in Guild of Heroes. Learn everything from basic gameplay to advanced tactics in our comprehensive guide.",
  backgroundImg: `url(${bgImg})`,
  buttonText: "LEARN HOW TO PLAY",
};

function GuildAccessSection() {
  return (
    <>
      <Explore {...MarketPlace} />
      <Explore {...PlayList} />
    </>
  );
}

export default GuildAccessSection;
