import Dmodel from "../../../Components/Dmodel";
import Button from "../../../Components/Dbutton";

const ConfirmationModal = ({ open, onClose,onStay, onExit }) => {
  return (
    <Dmodel
      onClose={() => {
        onClose();
      }}
      isActive={open}
      extraClasses="login-modal"
    >
      <div className="d-model-text wel-modal">
        <h3>Confirmation!</h3>
        <p>
          You've clicked outside the game. Continuing will stop your game, but
          you'll keep your earned airdrop coins.
        </p>
        <div className="flex" style={{ marginTop: "12px" }}>
          <Button
            variant="primary-contained"
            text="Stay"
            onClick={() => {
              onStay();
            }}
            fullWidth
          />
        </div>
        <div className="flex">
          <Button
            variant="secondary-contained"
            text="Exit"
            onClick={() => {
              onExit();
            }}
            fullWidth
          />
        </div>
      </div>
    </Dmodel>
  );
};

export default ConfirmationModal;
