import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";
import { airdropData } from "../../../Constants/list";
import TextInput from "../../../Components/Dinput";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
// import { useAccount, useDisconnect } from "wagmi";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  useGetUserByIdMutation,
  useLoginWithWalletMutation,
} from "../../../services/user";
import { toast } from "react-toastify";
import { setToken } from "../../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { generateRandomUsername } from "../../../utils/helper";
import {
  useWalletModal,
} from "@solana/wallet-adapter-react-ui";
const DWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet.svg`;
const CopyIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/copy-icon.svg`;

const DairdropModel = ({ id, isActive, onClose }) => {
  const [isData, setIsData] = useState();
  const [loginWithWallet, { isLoading: isConneting }] =
    useLoginWithWalletMutation();
  const [getUserById, { isLoading }] = useGetUserByIdMutation();
  const { user } = useSelector((state) => state.auth);
  const [isLoggedIn] = useState(user ? true : false);
  const dispatch = useDispatch();

  // const { address, connector } = useAccount();
  // const { open } = useWeb3Modal();
  // const { disconnect } = useDisconnect();
  const { connected, publicKey, disconnect } = useWallet();
  const { setVisible } = useWalletModal();

  useEffect(() => {
    const loginUser = async () => {
      if (!publicKey || isLoggedIn) return; // Guard against duplicate login

      const body = {
        walletId: publicKey,
        userName: generateRandomUsername(),
      };

      try {
        const response = await loginWithWallet(body);
        const { playFabData, token } = response?.data?.data;
        const { EntityToken, PlayFabId, SessionTicket } = playFabData?.data;

        localStorage.setItem("token", SessionTicket);
        localStorage.setItem("userId", PlayFabId);
        localStorage.setItem("entityToken", EntityToken);
        localStorage.setItem("jwtToken", token);

        window.PlayFab._internalSettings.entityToken = EntityToken;
        window.PlayFab._internalSettings.sessionTicket = SessionTicket;
        window.PlayFab.settings.titleId =
          process.env.REACT_APP_PLAYFAB_TITLE_ID;
        window.PlayFab.settings.developerSecretKey =
          process.env.REACT_APP_PLAYFAB_SECRET_KEY;

        dispatch(setToken({ token: SessionTicket, jwtToken: token }));
        await getUserById(PlayFabId);
        onClose();
      } catch (error) {
        toast.error("Failed to login with wallet");
      }
    };

    // Call loginUser if the wallet is connected
    if (connected && publicKey && !isLoggedIn) {
      loginUser();
    }

    // Dependency array includes publicKey and connected to trigger when they change
  }, [
    publicKey,
    connected,
    isLoggedIn,
    loginWithWallet,
    getUserById,
    dispatch,
    onClose,
  ]);

  const copyToClipboard = () => {
    if (publicKey) {
      navigator.clipboard
        .writeText(publicKey)
        .then(() => {
          toast.success("Wallet Address copied!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to copy text: ");
        });
    } else {
      toast.error("No wallet Address to copy!");
    }
  };

  useEffect(() => {
    const currentItem = airdropData.find((item) => item.id === id);
    setIsData(currentItem);
  }, [id]);

  const shortenAddress = () => {
    if (!publicKey) return "";
    return (
      publicKey.toString()?.slice(0, 8) +
      "..." +
      publicKey.toString()?.slice(publicKey.toString().length - 10)
    );
  };

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
          setIsData();
        }}
        isActive={isActive}
        extraClasses="p-0"
      >
        <div
          className="d-icon"
          style={{
            background: "#fff",
            boxShadow: `0 0 50px #fff`,
          }}
        >
          <img src={DWallet} alt={isData?.title} />
        </div>
        <div className="d-model-text">
          {!publicKey ? (
            <>
              <h3>{isData?.title}</h3>
              <p>
                Connect your cryptocurrency wallet. If you don’t have one, you
                can create a Solana-compatible wallet like Phantom or Solflare.
                Guild of Heroes will be on Solana, so make sure your
                wallet supports it.{" "}
              </p>
              <Button
                variant="primary-contained"
                text="Connect your wallet"
                extraClasses="w-full"
                onClick={() => setVisible(true)}
              />
            </>
          ) : isLoading || isConneting ? (
            <div
              style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Connecting your wallet please wait...
              <div className="loader" style={{ marginTop: "20px" }}></div>
            </div>
          ) : (
            <>
              <h3>Wallet Connected</h3>
              <p>Your cryptocurrency wallet is successfully connected</p>
              <TextInput
                type="text"
                value={shortenAddress()}
                // onChange={(e) => setIsValue(e.target.value)}
                extraClasses="wallet-input"
                rightNode={
                  <img
                    src={CopyIcon}
                    alt="copy-icon"
                    onClick={() => {
                      copyToClipboard();
                    }}
                    className="input-node-icon"
                  />
                }
              />
              <Button
                variant="primary-contained"
                text="disconnect"
                extraClasses="w-full"
                onClick={() => {
                  disconnect();
                }}
              />
            </>
          )}

          {/* {!isConfirm ? (
            <>
              <h3>{isData?.title}</h3>
              <p>
                Connect your cryptocurrency wallet. If you don’t have one, you
                can create an EVM-compatible wallet. Guild of Heroes will be on
                Avalanche, so make sure your wallet supports it.{" "}
              </p>
              <Button
                variant="primary-contained"
                text="Connect your wallet"
                extraClasses="w-full"
                onClick={() => setIsConfirm(true)}
              />
            </>
          ) : (
            <>
              {!isInput ? (
                <>
                  <h3>{isData?.title}</h3>
                  <p>
                    Connect your cryptocurrency wallet. If you don't have one,
                    create one in your Telegram account
                  </p>

                  <Button
                    variant="primary-contained"
                    text="Check it out"
                    extraClasses="w-full"
                    onClick={() => setIsInput(true)}
                  />
                </>
              ) : (
                <>
                  <h3>Wallet Connected</h3>
                  <p>Your cryptocurrency wallet is successfully connected</p>
                  <TextInput
                    type="text"
                    value={isValue}
                    onChange={(e) => setIsValue(e.target.value)}
                    extraClasses="wallet-input"
                  />
                </>
              )}
            </>
          )} */}
        </div>
      </DModel>
    </>
  );
};

export default DairdropModel;
