/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice";
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

const options = {
  autoClose: false,
  closeButton: closeButton,
  closeOnClick: false,
  type: toast.TYPE.WARNING,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};
const options2 = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const AccountSettings = ({ setAccountProgress }) => {
  const { playFabInfo, user } = useSelector((state) => state.auth);
  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState(null);
  const [form, setForm] = useState({
    username: playFabInfo?.AccountInfo?.TitleInfo
      ? playFabInfo?.AccountInfo?.TitleInfo?.DisplayName
      : playFabInfo?.AccountInfo.GoogleInfo
      ? playFabInfo?.AccountInfo.GoogleInfo.GoogleName
      : playFabInfo?.AccountInfo.FacebookInfo
      ? playFabInfo?.AccountInfo.FacebookInfo.FullName
      : playFabInfo?.AccountInfo.Username,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handler = () => {
    handlePasswordReset();
    toast.dismiss();
  };

  useEffect(() => {
    if (form.username) {
      setAccountProgress(true);
    }
  }, []);

  const handlePasswordChange = () => {
    toast(
      <Modal
        message="Changing your password will require you to log in again on all devices. Please ensure you remember your new password or have it stored securely."
        action="Continue with password change?"
        handler={handler}
        btnText="Proceed"
      />,
      options
    );
  };

  const handlePasswordReset = async () => {
    try {
      const response = await fetch(
        "https://0jpmsedk45.execute-api.us-east-1.amazonaws.com/default/sendPasswordResetEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user?.email,
          }),
        }
      );

      const data = await response.json();

      if (data.message) {
        setEmailSent(true);
        toast(
          <Modal
            message={"Reset password link sent! Check your inbox."}
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          options2
        );

        navigate("/home");
        dispatch(logout());
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      } else {
        console.error("Unexpected response:", data.error);
        setErrors(data.error);
      }
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setErrors(error);
    }
  };

  // THis function is used to mask the username
  const maskedUsername = (username) => {
    const maskedLocalPart =
      username?.slice(0, 3) + "*".repeat(Math.max(0, username?.length - 3));

    return maskedLocalPart;
  };

  const isBtnDisabled =
    !user?.email ||
    playFabInfo?.AccountInfo.TitleInfo.Origination === "Google" ||
    playFabInfo?.AccountInfo.TitleInfo.Origination === "Facebook";

  return (
    <div className="tbbox accountsetting" id="accountsetting">
      <h5>Account Settings</h5>
      <p>
        Regularly refresh your password to maintain account security and limit
        unauthorized access.
      </p>
      <form className="input-container" autoComplete="off">
        <label className={form.username ? "has-value disabled" : " disabled"}>
          <span>username</span>
          <input
            disabled
            type="text"
            value={maskedUsername(form.username)}
            onChange={(e) =>
              setForm({
                ...form,
                username: e.target.value,
              })
            }
            autoComplete="off"
          />
        </label>
      </form>
      <h5>Change Password</h5>
      <button
        style={{ marginBottom: "20px" }}
        disabled={isBtnDisabled}
        className={`btn update-btn ${isBtnDisabled && "btn-disabled"}`}
        onClick={handlePasswordChange}
      >
        Request Password Change
      </button>
      {emailSent && (
        <p style={{ color: "#37A512", width: "100%", margin: 0 }}>
          Email sent! If you have not received an email check your spam folder
        </p>
      )}
      {errors && (
        <p style={{ color: "red", width: "100%", margin: 0 }}>{errors}</p>
      )}
    </div>
  );
};

export default AccountSettings;
