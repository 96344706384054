/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Components/Loader";
// import { useNavigate } from "react-router-dom";
// import Modal from "../Components/Modal";
// import { toast } from "react-toastify";
// import { useAccount, useDisconnect } from "wagmi";
// import { useAccount } from "wagmi";
import { addPlayerAirdropStep } from "../utils/helper";
import { useWallet } from "@solana/wallet-adapter-react";
// import Cookies from "js-cookie";
// const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

// Options for toast
// const option = {
//   autoClose: 2000,
//   closeButton: closeButton,
//   closeOnClick: true,
//   type: toast.TYPE.SUCCESS,
//   hideProgressBar: true,
//   position: "top-center",
//   style: { border: "1px solid #d0a755" },
// };

// Create auth context
const AuthContext = createContext();

// Export auth context
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  // const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  // const { disconnect } = useDisconnect();

  // const navigate = useNavigate();
  // const [searchParam] = useSearchParams();
  // const { address } = useAccount();
  const { publicKey } = useWallet();

  // Requesting facebook access token for login purposes
  // const handleFacebookLogin = () => {
  //   window.FB.login(
  //     (response) => {
  //       if (response.authResponse) {
  //         console.log("Welcome! Fetching your information.... ");
  //         window.FB.api("/me", function (response) {
  //           console.log("Good to see you, " + response.name + ".");
  //         });
  //         const accessToken = response.authResponse.accessToken;
  //         loginWithFacebook(accessToken);
  //       } else {
  //         console.log("User cancelled login or did not fully authorize.");
  //       }
  //     },
  //     { scope: "public_profile,email", return_scopes: true }
  //   );
  // };

  // Using the access token to link playfab account with facebook account, creating new account if the account is not already linked
  // const loginWithFacebook = (accessToken) => {
  //   window.PlayFab.ClientApi.LoginWithFacebook(
  //     {
  //       AccessToken: accessToken,
  //       CreateAccount: true,
  //       TitleId: process.env.REACT_APP_PLAYFAB_TITLE_ID,
  //     },
  //     (result, error) => {
  //       if (result) {
  //         console.log("User logged in with PlayFab through Facebook:", result);

  // Storing PlayFab authentication context to maintain session management
  // Cookies.set(
  //   "authContext",
  //   JSON.stringify(
  //     window.PlayFab._internalSettings.authenticationContext
  //   ),
  //   { expires: 7 }
  // );
  // localStorage.setItem(
  //   "authContext",
  //   JSON.stringify(
  //     window.PlayFab._internalSettings.authenticationContext
  //   )
  // );
  // login(result.data.PlayFabId);
  //         navigate("/");
  //         toast(
  //           <Modal
  //             message="Login successfull. Welcome adventurer!"
  //             action=""
  //             handler={toast.dismiss()}
  //             btnText=""
  //           />,
  //           option
  //         );
  //       } else if (error) {
  //         console.error(
  //           "Error logging in with PlayFab through Facebook:",
  //           error
  //         );
  //       }
  //     }
  //   );
  // };

  // Using the access token to connect PlayFab account with the google account and creating new one if it doesn't exists
  // const onSignIn = (token) => {
  //   console.log("Attempting PlayFab Sign-in using LoginWithGoogleAccount");
  //   window.PlayFab.ClientApi.LoginWithGoogleAccount(
  //     {
  //       AccessToken: token,
  //       CreateAccount: true,
  //       TitleId: process.env.REACT_APP_PLAYFAB_TITLE_ID,
  //       InfoRequestParameters: { GetUserAccountInfo: true },
  //     },
  //     onPlayFabResponse
  //   );
  // };

  // Callback function for logging in, storing the authentication context for session management
  // function onPlayFabResponse(response, error) {
  //   if (response) {
  //Storing authentication context here
  // Cookies.set(
  //   "authContext",
  //   JSON.stringify(window.PlayFab._internalSettings.authenticationContext),
  //   { expires: 7 }
  // );
  // localStorage.setItem(
  //   "authContext",
  //   JSON.stringify(window.PlayFab._internalSettings.authenticationContext)
  // );
  // login(response.data.PlayFabId);
  //     navigate("/");
  //     toast(
  //       <Modal
  //         message="Login successfull. Welcome adventurer!"
  //         action=""
  //         handler={toast.dismiss()}
  //         btnText=""
  //       />,
  //       option
  //     );
  //   }
  //   if (error) console.log("Error: " + JSON.stringify(error));
  // }

  //Login function used to set the user data in order to tell the app, the user is successfully logged in.
  // const login = (userId) => {
  //   try {
  //     window.PlayFab.ClientApi.GetAccountInfo(
  //       { PlayFabId: userId },
  //       (result, error) => {
  //         if (result) {
  //           setUser(result);
  //           getUserData(userId);
  //           setLoading(false);
  //         } else {
  //           setUser(null);
  //           setLoading(false);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error login:", error);
  //     setUser(null);
  //     setLoading(false);
  //   }
  // };

  // const getUserData = (userId) => {
  //   try {
  //     window.PlayFab.ClientApi.GetUserData(
  //       { PlayFabId: userId },
  //       (result, error) => {
  //         if (result) {
  //           setUserData(result?.data?.Data);
  //         } else {
  //           setUserData(null);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error login:", error);
  //     setUserData(null);
  //   }
  // };

  // Function to monitor wallet connect so the rewards can be awarded or vice versa
  const handleConnectWallet = () => {
    if (publicKey) {
      addPlayerAirdropStep("Wallet Connected");
    }
    setWalletAddress(publicKey);
  };

  // Setting the global state of wallet address upon any change in the address, connecting or disconnecting
  useEffect(() => {
    handleConnectWallet();
    setLoading(false);
  }, [publicKey]);

  // Initializing the google upon loading the first time so the user can request access token for logging purposes
  useEffect(() => {
    // setLoading(true);
    // Check if the context exist in the query params incase user is redirected from nx entertainment. In this case, set the cookies
    // const queryContext = searchParam.get("authContext");
    // const queryRemember = searchParam.get("remember");
    // if (queryContext && queryRemember) {
    //   if (queryRemember === "true") {
    //     Cookies.set("authContext", queryContext, { expires: 7 });
    //   } else {
    //     Cookies.set("authContext", queryContext);
    //   }
    // }
    // Getting the previously saved playfab authentication context in injecting it again for maintaing sessions.
    // const authContext = Cookies.get("authContext");
    // if (window.location.pathname.includes("reset-password")) {
    //   setLoading(false);
    //   return;
    // }
    // if (authContext) {
    //   const authJSON = JSON.parse(decodeURIComponent(authContext));
    //   window.PlayFab._internalSettings.authenticationContext = {
    //     ...authJSON,
    //   };
    //   window.PlayFab._internalSettings.entityToken = authJSON.EntityToken;
    //   window.PlayFab._internalSettings.sessionTicket = authJSON.SessionTicket;
    //   // login(authJSON.PlayFabId);
    // } else {
    //   setLoading(false);
    // }
  }, []);

  // This make sures the user is redirected  to the home page if they try to go to login or sign up after logging in
  // useEffect(() => {
  //   if (user) {
  //     if (
  //       window.location.pathname.includes("login") ||
  //       window.location.pathname.includes("sign-up")
  //     ) {
  //       navigate("/");
  //     }
  //   }
  // }, [user]);

  // Logout function, removing all the authentication context, users and access tokens.
  // const logout = () => {
  // disconnect();
  // localStorage.removeItem("authContext");
  // Cookies.remove("authContext");
  // localStorage.removeItem("accessToken");
  // localStorage.removeItem("twitterContext");
  // setUser(null);
  // };

  return (
    <AuthContext.Provider
      value={{
        walletAddress,
        // setLoading,
        // handleFacebookLogin,
      }}
    >
      {loading ? <Loader /> : children}
    </AuthContext.Provider>
  );
}
