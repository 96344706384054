import React, { useMemo } from "react";
import "./style.css";
import { playSound } from "../../utils/helper";

const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const Button = ({
  text,
  variant,
  extraClasses,
  icon,
  disabled,
  isLoading,
  fullWidth = false,
  onClick,
  ...rest
}) => {
  const getVariantClasses = useMemo(() => {
    switch (variant) {
      case "primary-contained":
        return "d-bg-primary-contained";
      case "secondary-contained":
        return "d-bg-secondary-contained";
      default:
        return "";
    }
  }, [variant]);

  return (
    <button
      className={`d-btn ${getVariantClasses} 
    ${extraClasses ? extraClasses : ""} 
    ${disabled ? "opacity-down-disable" : ""} 
    ${isLoading ? "isloading-button" : ""} 
    ${fullWidth ? "full-width" : ""}`}
      onClick={() => {
        onClick?.();
        playSound(MenuChangeSound);
      }}
      {...rest}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          {icon && <img src={icon} alt="icon" />}
          {variant === "secondary-contained" ? (
            <div className="d-gradient-text">{text}</div>
          ) : (
            text
          )}
        </>
      )}
    </button>
  );
};

export default Button;
