import React, { useCallback, useEffect, useRef, useState } from "react";
import NavLink from "../../Components/Dlinks";
import Button from "../../Components/Dbutton";
import Notification from "../../Components/DnotificationIcon";
import DProfile from "../../Components/DprofileCard";
import CountUpAnimation from "../../Components/CountUpAnimation";
import { useDispatch, useSelector } from "react-redux";
import {
  connectSocket,
  disconnectSocket,
  subscribeToEvens,
  subscribeToNotifications,
} from "../../services/socket";
import { playSound } from "../../utils/helper";

import { useLocation } from "react-router-dom";
import { setEvents } from "../../store/slices/eventSlice";
import {
  useGetNotificationsMutation,
  useUpdateNotificationsMutation,
} from "../../services/notification";
import { toast } from "react-toastify";
import {
  getMuteState,
  toggleMute,
  removeDuplicatesById,
} from "../../utils/helper";
import { useNavigation } from "../../Context/NavigationContext";

const PlayIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/play-icon.svg`;
const UsersIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/user-icons.svg`;
const SettingIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/setting-icon.svg`;
const AvtorImag = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/user-avtor.svg`;
const ToggleIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/toggle.svg`;
const ProfileIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/profile-icon.svg`;
const VolumeIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/volume.svg`;
const VolumeIconUnmute = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/Volumeunmute.svg`;
const TelegramIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/telegram-icon.svg`;
const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;
const NotificationSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/notification_v4.wav`;

const Dheader = ({ onOpenSiderbar, active }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { onlineUsersCount } = useSelector((state) => state.users);
  const { events } = useSelector((state) => state.events);
  const socketRef = useRef(null);
  const [socketNotification, setSocketNotification] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [getNotifications, { isLoading }] = useGetNotificationsMutation();
  const [updateNotifications] = useUpdateNotificationsMutation();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const { pathname } = useLocation();
  const { handleNavigation } = useNavigation();

  useEffect(() => {
    const currentMuteState = getMuteState();
    setIsMuted(currentMuteState);
  }, []);

  const handleMuteClick = () => {
    toggleMute();
    setIsMuted(!isMuted);
    playSound(MenuChangeSound);
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = connectSocket();

      subscribeToEvens((newEvent) => {
        // console.log("newEvent", newEvent);
        dispatch(setEvents([newEvent, ...events]));
      });
      subscribeToNotifications(user?.playFabId, (notification) => {
        // console.log("notification===>", notification);
        playSound(NotificationSound);
        setSocketNotification((prev) => {
          const updatedNotifications = [notification, ...prev];
          setUnreadCount(updatedNotifications.filter((n) => !n.read).length);
          return updatedNotifications;
        });
      });
    }

    return () => {
      if (socketRef.current) {
        disconnectSocket();
        socketRef.current = null;
      }
    };
  }, [dispatch, events, user]);

  const fetchLatestNotifications = useCallback(
    (isView = false) => {
      if (user) {
        getNotifications(user?.playFabId)
          .unwrap()
          .then((res) => {
            const combinedNotifications = removeDuplicatesById(res.data);
            setSocketNotification(combinedNotifications);
            !isView &&
              setUnreadCount(
                combinedNotifications.filter((n) => !n.read).length
              );
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
    [getNotifications]
  );

  useEffect(() => {
    fetchLatestNotifications();
  }, [fetchLatestNotifications]);

  const handleNotificationClick = () => {
    playSound(MenuChangeSound);
    setIsNotificationOpen(true);
    setUnreadCount(0);

    !isNotificationOpen && fetchLatestNotifications(true);
    isNotificationOpen && handleNotificationClose();
  };

  const handleNotificationClose = () => {
    setIsNotificationOpen(false);
    const unreadNotifications = socketNotification.filter((n) => !n.read);
    const ids = unreadNotifications.map((n) => n._id);

    if (ids.length > 0 && unreadCount === 0) {
      updateNotifications({ notificationIds: ids })
        .unwrap()
        .then(() => {
          setSocketNotification((prev) =>
            prev.map((n) => ({
              ...n,
              read: ids.includes(n._id) ? true : n.read,
            }))
          );
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const Gamedata = {
    image: AvtorImag,
    status: "In game",
    count: onlineUsersCount,
  };

  return (
    <div className="d-header">
      <div className="flex">
        <div className="d-toggle-menu">
          <img
            src={ToggleIcon}
            className={active ? "active" : ""}
            onClick={onOpenSiderbar}
            alt="Toggle Icon"
          />
        </div>
        <NavLink text="Back to site" leftIcon onClick={handleNavigation("/home")} />
      </div>
      <div className="flex-head">
        <div className="rakec">
          <img src={ProfileIcon} alt="profile-icon" />
          <div className="lerak">
            <p>GOH COINS EARNED</p>
            <span>
              <CountUpAnimation
                startCount={user?.gohCoins}
                amount={user?.gohCoins || "--"}
              />
            </span>
          </div>
        </div>

        <span
          onClick={handleNavigation("https://t.me/guildofheroes_Bot", true)}
        >
          <div className="d-notification-icon">
            <img src={TelegramIcon} alt="telegram-icon" />
          </div>
        </span>
        <span onClick={handleMuteClick}>
          <div className={`d-notification-icon ${isMuted ? "active" : ""}`}>
            <img
              src={isMuted ? VolumeIcon : VolumeIconUnmute}
              alt="volume-icon"
            />
          </div>
        </span>

        {user !== null && (
          <span onClick={handleNavigation("/friends-list")}>
            <div className="d-notification-icon">
              <img src={UsersIcon} alt="Users-icon" />
            </div>
          </span>
        )}
        {user !== null && (
          <span onClick={handleNavigation("/setting")}>
            <div
              className={`d-notification-icon ${
                pathname === "/setting" || pathname === "/setting/language"
                  ? "active"
                  : ""
              }`}
            >
              <img src={SettingIcon} alt="setting-icon" />
            </div>
          </span>
        )}

        {user !== null && (
          <Notification
            count={unreadCount}
            onClick={handleNotificationClick}
            loading={isLoading}
            data={socketNotification}
            onClose={handleNotificationClose}
            isNotificationOpen={isNotificationOpen}
          />
        )}
        <DProfile data={Gamedata} extraClasses="d-fit-w" />
        <Button
          variant="primary-contained"
          text="Play game"
          icon={PlayIcon}
          disabled={!user ? true : false}
        />
      </div>
    </div>
  );
};

export default Dheader;
