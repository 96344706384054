import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import DrewardFeedTable from "./DrewardFeedTable";
import DcardBox from "../../../Components/DcardBox";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigation } from "../../../Context/NavigationContext";
import { useNavigate } from "react-router-dom";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";

const LeftArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/left-arrow.svg`;
const MedalIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/medal-feed-icon.svg`;

const DplayMinigame = () => {
  const [isFeedVisible, setIsFeedVisible] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const {
    isIframeFocused,
    setIsIframeFocused,
    showConfirmationModal,
    isSidebarModelOpen,
    setShowConfirmationModal,
    pendingPath,
    setPendingPath,
    isExternalPath,
  } = useNavigation();
  const navigate = useNavigate();
  const { user, playFabInfo } = useSelector((state) => state.auth);
  const iframeRef = useRef(null);
  const entityToken = localStorage.getItem("entityToken");
  const userId = playFabInfo?.AccountInfo?.TitleInfo?.TitlePlayerAccount?.Id;

  useEffect(() => {
    const handleWindowBlur = () => {
      if (!showConfirmationModal) {
        setIsIframeFocused(true);
      }
    };

    window.addEventListener("blur", handleWindowBlur);

    return () => {
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [showConfirmationModal]);

  useEffect(() => {
    if (!user) {
      setIsLoginModal(true);
    } else {
      setIsLoginModal(false);
    }
  }, [user]);

  return (
    <>
      <div
        className={`d-content flex home-table-flex mini-game-play ${
          !isFeedVisible ? "play-feed-visible" : ""
        }`}
      >
        <div className="d-hero-col">
          <DcardBox variant="primary-contained" extraClasses="d-home-table">
            <iframe
              ref={iframeRef}
              src={`https://browser.guildofheroes.com/?entityToken=${encodeURIComponent(entityToken)}&userId=${userId}`}
              height="100%"
              width="100%"
              title="GOH Game"
              frameBorder="0"
              allowFullScreen
              scrolling="no"
              style={{
                ...((showConfirmationModal || isSidebarModelOpen || isLoginModal) && {
                  pointerEvents: "none",
                }),
              }}
            >
              {isIframeFocused && <div className="iframe-overlay"></div>}
            </iframe>
          </DcardBox>
        </div>

        <div className="d-table-col">
          {!isFeedVisible && (
            <button
              className="d-bsr-close"
              onClick={() => setIsFeedVisible(true)}
            >
              <img src={MedalIcon} alt="icon" />
            </button>
          )}
          {isFeedVisible && (
            <DcardBox variant="primary-contained" extraClasses="d-home-table">
              <div className="d-top-bar">
                <h2>Reward feed</h2>
                <button
                  className="d-bsr-close"
                  onClick={() => setIsFeedVisible(false)}
                >
                  <img src={LeftArrow} alt="icon" />
                </button>
              </div>
              <DrewardFeedTable />
            </DcardBox>
          )}
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onStay={() => {
            setShowConfirmationModal(false);
          }}
          onExit={() => {
            setShowConfirmationModal(false);
            setIsIframeFocused(false);
            if (pendingPath) {
              isExternalPath
                ? window.open(pendingPath, "_blank")
                : navigate(pendingPath);
              setPendingPath(null);
            }
          }}
        />
      )}

      {isLoginModal && (
        <DloginModal
          onClose={() => {
            setIsLoginModal(false);
            if(!user){
              navigate('/dashboard')
            }
          }}
          isActive={isLoginModal}
          description="To play the browser mini-game and earn GOH airdrop coins, you need an account with Guild of Heroes."
        />
      )}
    </>
  );
};

export default DplayMinigame;
