import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";
import { useUpdateUserProfileMutation } from "../../../services/user";
import { ErrorOption, SuccessOption } from "../../../utils/toastOptions";
import { toast } from "react-toastify";
import Modal from "../../../Components/Modal";
import { logout } from "../../../store/slices/authSlice";
import { useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";

const DdeleteAccountModel = ({ isActive, onClose }) => {
  const { user } = useSelector((state) => state.auth);
  const [updateProfile] = useUpdateUserProfileMutation();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const deleteAccount = () => {
    updateProfile({ userId: user?._id, isDeleted: true })
      .unwrap()
      .then(() => {
        toast.dismiss();
        dispatch(logout());
        disconnect();
        navigate("/home", { replace: true });
        toast(
          <Modal
            message={`Account deleted successfully!`}
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          SuccessOption
        );
      })
      .catch((err) => {
        toast(
          <Modal
            message={`Failed to delete account`}
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          ErrorOption
        );
      });
  };

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
      >
        <div className="d-model-text del-modal">
          <h3>delete account</h3>
          <span>Are you sure you want to delete your account?</span>
          <p>
            All your data, including game progress, achievements, and purchases,
            will be permanently deleted. This action cannot be undone.
          </p>
          <Button
            variant="secondary-contained"
            text="Delete account"
            extraClasses="full-contain"
            onClick={() => {
              deleteAccount();
              onClose();
            }}
          />

          <Button
            variant="primary-contained"
            text="Cancel"
            extraClasses="w-full"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      </DModel>
    </>
  );
};

export default DdeleteAccountModel;
