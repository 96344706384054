import React, { useState } from "react";
import DSwitch from "../Dswitch";
import OutsideClickHandler from "../OutsideClickHandler";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { toggleVideo } from "../../store/slices/videoSlice";
import { playSound } from "../../utils/helper";
import { truncateID } from "../../utils/common";
import "./style.css";
import { useDisconnect } from "wagmi";
const ArrowDown = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-down.svg`;
// const WalletIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/wallet-icon-orange.svg`;
const LogOutIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/logout-fill.svg`;
const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const Dprofile = ({ data, extraClasses, isdropdown, isClose, isProfile }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();

  const handleVideoClick = () => {
    dispatch(toggleVideo());
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    playSound(MenuChangeSound);
  };

  const toggleAnimation = () => {
    setAnimating(false);
    setActive(!active);
  };

  const handleLogout = () => {
    playSound(MenuChangeSound)
    dispatch(logout());
    disconnect();
    navigate("/home", { replace: true });
  };

  // List items array
  const listItems = [
    // {
    //   key: "wallet",
    //   onClick: () =>{ 
    //     playSound(MenuChangeSound)
    //     navigate("/airdrop?wallet=true")},
    //   icon: WalletIcon,
    //   label: "Connect Wallet",
    // },
    {
      key: "effects",
      onClick: () => {
        playSound(MenuChangeSound)
        handleVideoClick();
        toggleAnimation();
      },
      component: (
        <DSwitch
          active={active}
          animating={animating}
          onClick={() => {
            playSound(MenuChangeSound)
            handleVideoClick();
            toggleAnimation();
          }}
        />
      ),
      label: active ? "EFFECTS TURN ON" : "EFFECTS TURN OFF",
    },
    {
      key: "logout",
      onClick: handleLogout,
      icon: LogOutIcon,
      label: "Logout",
    },
  ];

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => setIsDropdownOpen(false)}
        extraClasses="out-side-closer"
      >
        <div
          className={`d-profile-card ${extraClasses ? extraClasses : ""}`}
          onClick={toggleDropdown}
        >
          <div className={`d-profile-image ${isProfile?'profile-border-line':''}`} >
            <img src={data.image} alt="profile-img" />

            {/* {data?.count?.toString() && (
              <div className={`${isdropdown && "rank-tooltip"} d-tool-tip`}>
                {isdropdown && <h4>Rank</h4>}
                {data?.count}
              </div>
            )} */}
          </div>

          {!isClose && (
            <div className="d-profile-content">
              {data.title && <h4>{data.title}</h4>}
              {data.id && <p>{truncateID(data.id)}</p>}
              {data.status && <div className="isOnline">{data.status}</div>}
            </div>
          )}
          {data.subItem && (
            <img
              src={ArrowDown}
              alt="arrow-down"
              className={isDropdownOpen ? "icon-revert" : "icon-straight"}
            />
          )}
        </div>
        {isdropdown && isDropdownOpen && (
          <div className="pro-card-wrapper">
            <ul>
              {listItems.map((item) => (
                <li
                  key={item.key}
                  onClick={item.onClick}
                  style={{ justifyContent: isClose ? "center" : "start" }}
                >
                  {item.icon && <img src={item.icon} alt="" />}
                  {item.component && item.component}
                  {!isClose && item.label && item.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default Dprofile;
