import React, { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import "./style.css";
import DcardBox from "../../../Components/DcardBox";
import { eraningDataList } from "../../../Constants";
import HowItWorks from "./HowItWorks";
import { useSelector } from "react-redux";
import {
  useGetCombatCardsMutation,
  useGetCombatEarningMutation,
} from "../../../services/combatEarning";
import { formatNumberWithCommas, mergeData } from "../../../utils/helper";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
// const LogoHoursIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const QuestionCircle = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/question-Circle.svg`;

const text = ` Engage in the full Guild of Heroes experience on PC, where every
match and achievement can earn you gold. Whether you’re
eliminating players, destroying towers, securing NPC kills, or
simply spending time in-game, all your efforts contribute to your
overall gold earnings. Just like the tasks you complete on the
dashboard, this gold counts toward your airdrop rewards. Your
progress from each specific gold-earning activity will be
reflected down below and count up toward your overall gold
earnings—providing an additional way to see exactly how much
you’ve earned from playing the game without needing to take any
extra steps`;

const DcombatTask = () => {
  const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
  const [isExpandedText, setIsExpandedText] = useState(false);
  const [cardsData, setCardsData] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const [getCombatCards, { isLoading }] = useGetCombatCardsMutation();
  const [getCombatEarningsValues, { isLoading: isDataLoading }] =
    useGetCombatEarningMutation();

  useEffect(() => {
    getCombatCards()
      .unwrap()
      .then((res) => {
        const data = mergeData(res.data, eraningDataList(res.data));
        setCardsData(data);
        if (user) {
          getCombatEarningsValues(user?.playFabId)
            .unwrap()
            .then((result) => {
              const updatedDataWithValues = data?.map((item) => {
                const apiEntry = result.data.find(
                  (data) => data._id === item.type
                );
                return {
                  ...item,
                  totalRewardEarned: apiEntry
                    ? `${apiEntry.totalRewardEarned.toLocaleString()}`
                    : "0",
                };
              });

              setCardsData(updatedDataWithValues);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [getCombatCards, getCombatEarningsValues, user]);

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>combat earnings</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() =>{
               setIsDriveModalOpen(true)
              }}
          />
        </div>

        <div className="airdrop-banner combat-ban">
          <div>
            <h2>extra information</h2>
            <p>{isExpandedText ? text : text.slice(0, 300) + "..."}</p>
            <span
              className="d-gradient-text"
              onClick={() => setIsExpandedText(!isExpandedText)}
            >
              {!isExpandedText ? "show more" : "hide"}
            </span>
          </div>
          <div>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
        </div>

        {cardsData?.length === 0 && (isLoading || isDataLoading) && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}

        <div className="d-earnings-list">
          {cardsData?.map((item, index) => {
            return (
              <DcardBox
                variant="secondary-contained"
                extraClasses="earning-card"
                key={index}
              >
                <div>
                  <div className="icon-point">
                    <div>
                      <h3 className="hrad-text">{item.title}</h3>
                    </div>
                    <div>
                      <span>+{formatNumberWithCommas(item.reward)}</span>
                      <img
                        src={LogoIcon}
                        alt="Logo-icon"
                        className="tool-icon"
                      />
                      <img
                        src={QuestionCircle}
                        alt="QuestionCircle-icon"
                        className="ques"
                      />
                      <div className="info-tool-tip">
                        <p>{item?.tooltip}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-task-content">
                  <h2 className="d-gradient-text d-tit">
                    {item?.totalRewardEarned || 0}
                  </h2>
                  <p>{item?.description}</p>
                </div>
              </DcardBox>
            );
          })}
        </div>
      </div>

      <HowItWorks
        activeModel="stepone"
        isActive={isDriveModalOpen}
        onClose={() => setIsDriveModalOpen(false)}
      />
    </>
  );
};

export default DcombatTask;
